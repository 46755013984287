import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { OMailService } from 'src/app/shared/services/o-mail.service';
import { WebserviceService } from 'src/app/shared/services/webservice.service';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
})
export class AgreementsComponent implements OnInit {
  userAgreementForm: FormGroup;
  agrrementSubmitted = false;
  isAgreementAccepted = false;
  omailUserRegStatus = false;
  userDetails;
  acceptDate: Date;
  showSpinner = false;
  founderName = ''

  constructor(
    private omailService: OMailService,
    private _ls: LocalStorageService,
    public jwtHelper: JwtHelperService,
    private router: Router
  ) {
    this.userAgreementForm = new FormGroup({
      acceptTerms1: new FormControl(false, Validators.requiredTrue),
      acceptTerms2: new FormControl(false, Validators.requiredTrue),
      userName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.minLength(3),
      ]),
    });
  }

  ngOnInit(): void {
    this.userDetails = this._ls.getUserInfo();
    this.founderName = this._ls.getFounderName();
    this.checkUserAgreements();
    this.acceptDate = new Date();
  }
  get agreementF() {
    return this.userAgreementForm.controls;
  }
  checkUserAgreements() {
    const payload = {
      prodName: 'O-Mail',
      userName: this.userAgreementForm.value.userName,
    };
    if (this.userDetails.omailUserRegStatus) {
      this.omailService.getAgreements(payload).subscribe((res: any) => {
        if (res.flag) {
          this.router.navigate(['o-mail/o-mail-app']);
        } else {
          this.isAgreementAccepted = true;
        }
      });
    } else {
      this.omailUserRegStatus = true;
    }
  }
  saveAgreement() {
    this.agrrementSubmitted = true;
    // stop here if form is invalid
    if (this.userAgreementForm.invalid) {
      return;
    }

    if (this.userAgreementForm.controls.userName.value.trim() === '') {
      this.userAgreementForm.controls.userName.setValue('');
      return;
    }

    this.showSpinner = true;
    const payload = {
      prodName: 'O-Mail',
      userName: this.userAgreementForm.value.userName.trim(),
    };
    this.omailService.addAgreements(payload).subscribe((res: any) => {
      this.checkUserAgreements();
    });
  }
  alphaNumericSpecCharEvent(event) {
    const regex = /^[a-zA-Z0-9\s]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }
}
