import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: Date, timeZone: string, format: string): any {
    const givenDate = this.convertTZ(value, timeZone);
    return super.transform(givenDate, format);
  }

  convertTZ(date, tzString) {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tzString }
      )
    );
  }
}
