import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EncrDecrService } from '../services/encr-decr.service';
import { LocalStorageService } from '../services/local-storage.service';
import { TokenService } from '../services/token.service';
import { WebserviceService } from '../services/webservice.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _service: WebserviceService,
    private _ls: LocalStorageService,
    private _tokenService: TokenService,
    private _encDecrService: EncrDecrService,
    private _router: Router
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.checkAuth();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuth();
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkAuth();
  }

  checkAuth() {
    if (this._ls.isLoggedIn()) {
      const paymentStatus = this._ls.getFounderPaymentStatus();

      if (!paymentStatus) {
        return this._service.getFounderProfileDetails().pipe(
          map(
            (response) => {
              if (
                response.httpStatus?.toLowerCase() === 'ok' &&
                response.statusMsg?.toLowerCase() === 'success'
              ) {
                const founderDetails =
                  response.userProfileResponse['userDetails'];
                this._ls.setLoggedInUser('userDetails', founderDetails, true);
                this._tokenService.token = this._ls.getLoggedInUser(
                  'user',
                  true
                );
                return this.validateFounderPaymentStatusAndRedirect(
                  founderDetails['paymentStatus'],
                  true
                );
              } else {
                return false;
              }
            },
            catchError((error) => {
              return of(false);
            })
          )
        );
      } else {
        return this.validateFounderPaymentStatusAndRedirect(paymentStatus);
      }
    } else {
      this._router.navigate(['/auth/login']);
      return false;
    }
  }

  validateFounderPaymentStatusAndRedirect(
    paymentStatus: string,
    isEncrypted = false
  ) {
    const status = isEncrypted
      ? this._encDecrService.decrypt(paymentStatus)
      : paymentStatus;
    if (['confirmed', 'refund'].includes(status.toLowerCase())) {
      return true;
    } else {
      switch (status.toLowerCase()) {
        case 'suspended':
          this._router.navigate(['/account/suspended']);
          break;
        case 'terminated_refunded':
        case 'terminated_violated':
          this._router.navigate(['/account/terminated']);
          break;
        case 'pending':
          this._router.navigate(['/payment']);
          break;
        default:
          this._router.navigate(['/auth/login']);
      }
      return false;
    }
  }
}
