import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebserviceService } from 'src/app/shared/services/webservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private apiService: WebserviceService
  ) {}

  ngOnInit() {
    const id = this.aRoute.snapshot.queryParams['val'];
    if (id == null) {
      this.router.navigateByUrl('/auth/login');
    } else {
      this.apiService
        .commonMethod(
          'verifyEmailId',
          {
            searchValue: id,
          },
          'POST'
        )
        .subscribe(
          (res) => {
            if (res && res.status === 'httpStatus') {
              res.statusMsg !== 'FAIL'
                ? Swal.fire(
                    'Your email verified successfully',
                    'Please login to continue',
                    'success'
                  ).then(() => {
                    this.router.navigateByUrl('/auth/login');
                  })
                : Swal.fire(res.details[0], '', 'warning').then(() => {
                    this.router.navigateByUrl('/auth/login');
                  });
            }
          },
          (err) => {
            if (err.error && err.error.details) {
              Swal.fire(err.error.details[0], '', 'error').then(() => {
                this.router.navigateByUrl('/auth/login');
              });
            } else {
              Swal.fire('Error while verifying your email', '', 'error').then(
                () => {
                  this.router.navigateByUrl('/auth/login');
                }
              );
            }
          }
        );
    }
  }
}
