import { Directive, ElementRef, HostListener } from '@angular/core';
import { NAVIGATION_KEYS, NUMBERS } from 'src/app/util/constants';

@Directive({
  selector: '[onlyNumbers]',
})
export class OnlyNumberDirective {
  private key;
  inputValue;
  constructor(private eleRef: ElementRef) {}
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    this.key = event.key;
    this.inputValue = this.eleRef.nativeElement.value;
    if (
      NUMBERS.indexOf(this.key) === -1 &&
      NAVIGATION_KEYS.indexOf(this.key) === -1 &&
      '+'.indexOf(this.key) === -1 &&
      '-'.indexOf(this.key) === -1 &&
      '('.indexOf(this.key) === -1 &&
      ')'.indexOf(this.key) === -1
    ) {
      event.preventDefault();
    }
  }
}
