<div class="model-body">
  <div class="nda_one agreement-data-desc">
    <div class="text-center">
      <img
        class="info-img"
        src="/assets/images/icons/info-toaster.svg"
        alt="payment"
      />

      <h5>Disclaimer</h5>
    </div>
    <p>
      <strong
        >ONPASSIVE believes in Real business, with Real products involving Real
        employees.</strong
      >
      We are an AI Technology Company that builds fully autonomous SaaS products
      using the latest AI software technologies for our global customer base.
      The customers who aim to accelerate innovation and transform their
      businesses from strategy to designing, implementing, and managing get
      their subscription to our software products through the SaaS business
      model.
    </p>

    <p>
      ONPASSIVE <strong>does not</strong> believe in, encourage or endorse a
      so-called <strong>"Get-Rich- Quick"</strong> Model. We are not an MLM (
      Multi-Level Marketing ) Company; we are not into Chain Marketing.
      ONPASSIVE is not an investment platform. Neither we are into Stocks,
      Investment Instruments, nor do we guarantee or promise any secured
      income/returns at any level.
    </p>

    <p>
      Any sort of derogatory statements against our company is strictly
      forbidden. Publishing videos or content in online Social Mediums without
      substantial proof would be considered as an attempt of criminal
      defamation. The company reserves the absolute right to take legal actions
      against the perpetrators.
    </p>

    <p>
      <strong
        >O-Founders are the 'Early-Adopters' & future
        "Customers/Buyers/Potential Resellers" of ONPASSIVE Software
        products.</strong
      >
      However, the Model is yet to be defined as it depends upon the scale and
      reach of our envisioned products.
    </p>

    <p>
      If you were misled by any individual to join the O-Founder Community or If
      someone has ever promised or is promising you some sort of guaranteed
      returns -
      <strong
        >please do report at
        <a href="mailto:legal@onpassive.com">legal@onpassive.com</a>.</strong
      >
      We would be happy to refund your money through our O-Founders support
      system after appropriate validation and verification.
    </p>
    <div class="text-center">
      <img
        class="op-logo"
        src="/assets/images/my-links/onpassive-tab/Onpassive-Logo 16.png"
        alt="onpassive"
      />
    </div>
  </div>
  <div class="text-center mt-4">
    <button
      #okBtn
      class="custom-btn-fill"
      (click)="agreedTermsAndConditions(true)"
    >
      OK
    </button>
  </div>
</div>
