import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileTypeFinder',
})
export class FileTypeFinderPipe implements PipeTransform {
  imageFileTypes = ['jpeg', 'png', 'gif', 'bmp', 'jpg', 'jfif'];
  docTypes = ['pptx%0D', 'pptx', 'pdf%0D', 'pdf'];

  transform(filePath: string, allowdFileTypes = this.imageFileTypes): string {
    if (!filePath) {
      return 'none';
    }
    const fileType = filePath.split('.')[filePath.split('.').length - 1];

    if (allowdFileTypes.indexOf(fileType.toLowerCase()) > -1) {
      return 'image';
    }

    if (this.docTypes.indexOf(fileType) > -1) {
      return fileType.split('%0D')[0];
    }
    return fileType; // If it is video
  }
}
