import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss'],
})
export class PaymentErrorComponent {
  constructor(
    private router: Router,
    public activeModal: NgbActiveModal,
    private _ls: LocalStorageService
  ) {}

  close() {
    this.activeModal.close();
    this._ls.removeUser('isRedirectedToPaymentGateway');
    this.router.navigate(['/']);
  }
}
