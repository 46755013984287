import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EncrDecrService } from '../services/encr-decr.service';
import { LocalStorageService } from '../services/local-storage.service';
import { WebserviceService } from '../services/webservice.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard implements CanActivate {
  constructor(
    private _service: WebserviceService,
    private _ls: LocalStorageService,
    private _encDecService: EncrDecrService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const encryptedPaymentStatus = this._ls.getLoggedInUser(
      'userDetails',
      true
    )?.paymentStatus;
    const paymentStatus = this._encDecService.decrypt(encryptedPaymentStatus);
    if (!paymentStatus) {
      return this._service.getFounderProfileDetails().pipe(
        map(
          (response) => {
            if (
              response.status?.toLowerCase() === 'ok' &&
              response.statusMsg?.toLowerCase() === 'success'
            ) {
              const founderDetails =
                response.userProfileResponse['userDetails'];
              this._ls.setLoggedInUser('userDetails', founderDetails, true);
              if (
                this._encDecService
                  .decrypt(founderDetails['paymentStatus'])
                  .toLowerCase() === 'confirmed'
              ) {
                this.router.navigateByUrl('/dashboard');
                return false;
              }
              return true;
            } else {
              this.router.navigateByUrl('/login');
              return false;
            }
          },
          catchError((error) => {
            return of(false);
          })
        )
      );
    }

    return true;
  }
}
