<div class="container-fluid" *ngIf="omailUserRegStatus">
  <div class="omail-agreement">
    <div class="omail-agreement-data">
      <div class="row justify-content-between omail-agreement-data-top">
        <div class="col-md-12">
          <img
            src="../../../assets/images/omail/O-Mail-logo.png"
            alt="O-Mail-logo"
          />
        </div>
      </div>
      <div class="omail-agreement-data-desc">
        <h5>
          Thank you for your interest. The support team will be contacting you
          shortly about the availability of this service.
        </h5>
        <!-- <p class="text-uppercase"> </p> -->
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" *ngIf="isAgreementAccepted">
  <!-- omail agreement start  -->
  <div class="omail-agreement">
    <!-- <h4 class="text-center omail-agreement-title">Subscription Agreement</h4> -->
    <div class="omail-agreement-data">
      <div class="row justify-content-between omail-agreement-data-top">
        <div class="col-md-3 mb-3 mb-md-0 text-sm-center text-md-left">
          <img
            src="../../../assets/images/omail/O-Mail-logo.png"
            alt="O-Mail-logo"
          />
        </div>
        <div class="col-md-5 col-lg-6 mb-3 mb-md-0 pl-sm-0 text-sm-center">
          <!-- <h1 class="text-center mb-4">ONPASSIVE</h1>
          <h2 class="text-center mb-3">O-FOUNDERS SOFTWARE SUBSCRIPTION AGREEMENT</h2> -->
          <h2 class="text-center mb-3 main-color">USER TERMS AND CONDITIONS</h2>
        </div>
        <div class="col-md-4 col-lg-3 p-sm-0 text-sm-center text-md-left">
          <address>
            <p>
              <strong>Office Address</strong><br />
              7380 W Sand Lake Rd,<br />
              Suite 500-529, Orlando, FL 32819.<br />
              E-Mail Address:
              <a href="mailto:legal@onpassive.com">legal@onpassive.com</a>
            </p>
          </address>
        </div>
      </div>
      <div class="omail-agreement-data-desc">
        <h5>Important Notice</h5>
        <p class="text-uppercase">
          PLEASE NOTE THAT BY USING ONPASSIVE OR ANY OF ITS RELATED PRODUCTS AND
          SOLUTIONS YOU AGREE TO ABIDE AND BE BOUND BY THE TERMS & CONDITIONS
          LISTED IN THIS AGREEMENT. IF YOU DO NOT AGREE WITH THE TERMS &
          CONDITIONS LISTED HEREIN KINDLY STOP USING ONPASSIVE IN ANY MANNER
          WHATSOEVER, AND WRITE TO US TO INFORM US OF YOUR INTENT TO NOT AGREE
          WITH THIS AGREEMENT. 
        </p>
        <p>
          <strong
            >This Agreement is considered to be in force from the time that you
            sign up to use the ONPASSIVE platform and will continue to be
            in-force until you formally terminate this Agreement in writing by
            sending a notice to us at 
            <span class="text-blue">legal@onpassive.com</span></strong
          >
        </p>
        <h5>The agreement – Terms & Conditions</h5>
        <ol start="1">
          <li>
            <strong>Services</strong>
            <p>
              <strong>1.1.The right to access and use information.</strong>
              O-Mail therefore grants Founder/Customer a global, non-exclusive,
              non-transferable right to access and use O-Mails services for
              Customer's own business purposes during the applicable Term,
              subject to the terms and conditions of this Agreement and in
              payment of the fees. Users may exercise such limited rights on
              Customer's behalf.
            </p>
            <p>
              <strong>1.2.Restrictions.</strong> Founder/Customer access to and
              use of the Services during any term is subject to the limits set
              forth in an Order, SOW, and O-Mail’s Limits Policy and Acceptable
              Use Policy, both of which are available on the Site as of the
              commencement date of the term. Customer will not allow more than
              one User to use the same set of login credentials for a Service,
              and will not commercially sell, resell, licence, sub-license,
              distribute, or frame the Services to a third party. Customer must
              access and use the Services in accordance with this Agreement, the
              Documentation, and all applicable laws and regulations, and must
              inform O-Mail immediately if any unauthorized access or usage is
              discovered.
            </p>
          </li>
          <li>
            <strong>Customer Content Security and Processing</strong>
            <p>
              <strong> 2.1.Security:</strong> O-Mail has implemented and will
              continue to maintain information security practices and safeguards
              as described in the Security Practices available at
              www.onpassive.com, which include physical, organizational, and
              technical measures designed to protect the security, integrity,
              and confidentiality of the online Services and Customer Content,
              as well as to protect against information security threats. O-Mail
              has the right to amend such security policies and safeguards at
              any time, provided that any such modification does not
              dramatically decrease the overall degree of security or guarantees
              mentioned therein.
            </p>
            <p>
              <strong>2.2.Processing:</strong> Customer represents and
              guarantees that it has all rights, licenses, and consents
              necessary to: (a) submit all Customer Content to the Services; and
              (b) provide O-Mail the limited rights set out herein to process
              Customer Content. Customer hereby grants O-Mail a worldwide,
              non-exclusive, non-transferable right to use and otherwise process
              Customer Content under this Agreement only: (i) as required by
              applicable law; (ii) as requested in writing by Customer or as
              permitted by Customer via a Service's access controls; or (iii) as
              necessary to provide, support, or optimize the Services, or to
              prevent or address technical problems with the Services or
              violations of this Agreement.
            </p>
            <p>
              <strong
                >2.3.O-Mail may appoint third parties to act on its behalf in
                connection with O-Mail's provision of the services, provided
                that:</strong
              >
              (a) such third parties are bound by applicable confidentiality and
              data security obligations at least as protective as those set
              forth in this Agreement; and (b) O-Mail is responsible for such
              third parties' acts and omissions in relation to O-Mail’s
              obligations under this Agreement.
            </p>
          </li>
          <li>
            <strong>Intellectual Property & Proprietary Rights</strong>
            <p>
              <strong>3.1.O-Mail:</strong> Regardless of any other term in this
              Agreement, O-Mail owns all right, title, and interest in and to
              O-Mail Properties as between the parties. O-Mail does not grant
              Customer or any User any rights unless clearly stated in this
              Agreement.
            </p>
            <p>
              <strong>3.2.Customer:</strong> Customer maintains all right,
              title, and interest in Customer's Confidential Information,
              including Customer Content, as well as any intellectual property
              and proprietary rights therein, as between the parties. Except as
              specifically stated in this Agreement, O-Mail does not acquire any
              right, title, or interest in or to Customer Content from Customer
              under this Agreement.
            </p>
            <p>
              <strong>Feedback:</strong> Customer grants O-Mail a worldwide,
              irrevocable, perpetual, sublicensable, transferable, non-exclusive
              licence to use and incorporate any feedback or suggestions for
              enhancements that Customer or a User provides to O-Mail
              ("Feedback") into O-Mails products and services, without any
              obligation of compensation. Customer provides feedback "as is,"
              with no explicit or implied claims or guarantees, including any
              warranties of merchant-ability or fitness for a specific purpose.
            </p>
          </li>
          <li>
            <strong>Confidentiality</strong>
            <P>
              <strong>4.1.Confidential Information:</strong>
              "Confidential Information" refers to all non-public, proprietary,
              business, technical, legal, or financial information disclosed or
              learned in connection with this Agreement that the Disclosing
              Party has identified as confidential at the time of disclosure or
              that the Receiving Party would clearly understand as confidential
              based on the nature of the information or circumstances
              surrounding its disclosure. O-Mail Properties and Customer Content
              are examples of confidential information for O-Mail and Customer,
              respectively. Despite the preceding definition, Confidential
              Information does not include: (a) information that was generally
              known to the public at the time it was disclosed to the Receiving
              Party; (b) information that becomes generally known to the public
              after disclosure to the Receiving Party; (c) information that was
              in the Receiving Party's possession free of any obligation of
              confidentiality prior to disclosure to the Receiving Party; (d)
              information that the Receiving Party lawfully got from a third
              party without limitation on disclosure; or (e) information that
              the Receiving Party independently produced without reference to or
              use of the Disclosing Party's Confidential Information. ALL
              CONFIDENTIAL DATA IS DISTRIBUTED "AS IS." EXCEPT FOR THE
              WARRANTIES EXPRESSLY STATED IN THIS AGREEMENT, NEITHER PARTY MAKES
              ANY WARRANTIES, EXPRESSED OR IMPLIED, AS TO THE ACCURACY OR
              COMPLETENESS OF ITS CONFIDENTIAL INFORMATION, EXPRESSED OR
              IMPLIED.
            </P>
            <P
              ><strong>4.2.Use and Disclosure of Confidential:</strong> The
              Receiving Party: (a) will not use the Disclosing Party's
              Confidential Information for any purpose other than those
              permitted under this Agreement; (b) will not disclose, give access
              to, or distribute any of the Disclosing Party's Confidential
              Information to any third party, except as expressly authorized in
              this Agreement or a separate written agreement signed by the
              Disclosing Party; and (c) will take reasonable security
              precautions to safeguard Disclosing Party confidential
              information. Despite the foregoing, the Receiving Party may
              disclose Confidential Information to those of its employees,
              directors, Affiliates, advisors, agents, contractors, and other
              representatives ("Representatives") who need to know such
              information in order to exercise their respective rights and
              obligations hereunder, on the condition that each such
              Representative is bound to protect the Confidential Information by
              confidentiality obligations substantially similar to those that
              protect the Receiving Party's own confidential information.
            </P>
            <P
              ><strong>4.3.Required Disclosures:</strong> The Receiving Party
              may disclose Confidential Information to the extent required by
              law or legal process, provided that the Receiving Party: (a)
              provides the Disclosing Party with prior written notice of such
              disclosure to allow the Disclosing Party a reasonable opportunity
              to appear, object, and obtain a protective order or other
              appropriate relief regarding such disclosure; and (b) uses
              diligent efforts to limit disclosure to the extent required by law
              or legal process.
            </P>
            <P
              ><strong>4.4.Return & Deletion:</strong> The Receiving Party will
              (a) return or destroy all tangible documents and media in its
              possession or control that contain the Disclosing Party's
              Confidential Information upon written request by the Disclosing
              Party; (b) delete electronically stored Confidential Information
              of the Disclosing Party in its possession or control; Despite the
              foregoing, (i) the Receiving Party will not be obligated to render
              unrecoverable Confidential Information of the Disclosing Party
              contained in an archived computer system backup made in accordance
              with the Receiving Party's legal and financial compliance
              obligations or security and disaster recovery procedure; and (ii)
              O-Mail will return and delete Customer Content as set forth in
              this agreement to any such retained Confidential Information.
            </P>
            <P
              ><strong>Remedies:</strong> Any real or threatened breach of
              Section 4 (Confidentiality) by the Receiving Party may cause
              irreparable, non-monetary harm to the Disclosing Party, the degree
              of which may be difficult to determine. As a result, the
              Disclosing Party has the right (but not the obligation) to seek
              injunctive action to prevent or minimize any breaches of Section 4
              involving the Disclosing Party's Confidential Information, as well
              as any damages that may follow from those breaches.
            </P>
          </li>
          <li>
            <strong>Representations & Warranties</strong>
            <P
              ><strong> 5.1.Warranty of Authority and Compliance:</strong>
              O-Mail represents and declares that it has the legal ability to
              engage into this Agreement and that it will follow all applicable
              laws and regulations in providing the Services under this
              Agreement. To be clear, O-Mail is not responsible for Customer's
              compliance with any laws or regulations that apply to Customer and
              its industry.
            </P>
            <P
              ><strong
                >5.2.Subscription Services have a limited warranty:</strong
              >
              O-Mail represents and promises that the Subscription Services will
              function substantially as stated in the applicable Documentation
              for the applicable Term. O-Mail shall make commercially reasonable
              efforts to rectify or remedy any claimed failure to comply with
              this guarantee after receiving written notice from Customer.
            </P>
            <P
              ><strong>5.3.Disclaimer:</strong> O-Mail MAKES NO REPRESENTATIONS
              AND DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, EXCEPT FOR THOSE EXPRESSLY STATED IN THIS AGREEMENT.
              O-Mail EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, COMPLIANCE WITH
              LAWS, NON-INFRINGEMENT, AND ACCURACY, WITHOUT LIMITING THE
              GENERALITY OF THE FOREGOING. AND O-Mail DOES NOT GUARANTEE THAT
              THE SERVICES OR THIRD-PARTY APPLICATIONS AND SERVICES WILL BE
              ERROR-FREE OR FUNCTION WITHOUT INTERRUPTIONS OR DOWNTIME.
            </P>
          </li>
          <li>
            <strong>Limitation of Liability</strong>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER PARTY WILL BE
              LIABLE FOR ANY LOST PROFITS, GOODWILL, OR REVENUES, OR FOR ANY
              INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT, COVER, BUSINESS
              INTERRUPTION, OR PUNITIVE DAMAGES IN CONNECTION WITH ANY CLAIM OF
              ANY NATURE, WHETHER IN CONTRACT, TORT, OR UNDER. TO THE EXTENT
              PERMITTED BY LAW, EACH PARTY'S TOTAL LIABILITY UNDER THIS
              AGREEMENT SHALL BE LIMITED TO THE FEES PAID BY CUSTOMER TO O-Mail
              UNDER THIS AGREEMENT FOR THE SERVICES GIVING RISE TO THE LIABILITY
              DURING THE TWELVE (12) MONTHS PRIOR TO THE DATE ON WHICH THE
              LIABILITY AROSE. THIS LIMIT WILL NOT BE EXTENDED IF MORE THAN ONE
              CLAIM EXISTS.
            </p>
          </li>
          <li>
            <strong>General</strong>
            <p>
              <strong> 7.1.Publicity:</strong>
              O-Mail may reveal Customer as a customer of O-Mail or the
              identified Services utilized by Customer, or may use Customer's
              name and logo on the Site or in O-Mail’s promotional materials,
              unless Customer has notified O-Mail in writing (including through
              email) to the contrary.
            </p>
            <p>
              <strong>7.2.Assignment:</strong> In the event of a merger or
              similar transaction, or if a company acquires substantially all of
              its assets, equity, or business, either party may assign this
              Agreement and any Orders or SOWs to a third party without the
              other party's prior written consent; otherwise, neither party may
              assign this Agreement or any Orders or SOWs to a third party
              without the other party's prior written consent. Subject to the
              above and notwithstanding any limits on transfer-ability under
              this Agreement, the assigning party will notify the non-assigning
              party of any allowed assignment, and this Agreement, together with
              any Orders or SOWs, will bind and benefit the parties.
            </p>
            <p>
              <strong>7.3.Force Majeure:</strong> A party is not responsible for
              any delay or default under this Agreement if the delay or default
              is caused by circumstances beyond its reasonable control, and the
              party experiencing such circumstances makes reasonable measures to
              minimize the consequences of such circumstances.
            </p>
            <p>
              <strong>7.4.Amendment Waiver:</strong> Unless otherwise indicated
              herein, only a formal agreement signed by an authorized
              representative of each party can modify this Agreement and any
              Orders or SOWs. Any violation of this Agreement, any Order, or any
              SOW shall only be valid if it is in writing, and no such waiver
              will function or be considered as a waiver of any subsequent
              breach.
            </p>
            <p>
              <strong>7.5.Enforce-ability: </strong> If any provision of this
              Agreement, any Order, or any SOW is found to be unenforceable,
              that provision will be construed either by modifying it to the
              minimum extent necessary to make it enforceable (if permitted by
              law) or by disregarding it (if not permitted by law), with the
              rest of this Agreement, or the relevant Order, or SOW, remaining
              in full force and effect. Regardless of the above, if changing or
              disregarding the unenforceable provision results in the failure of
              an important purpose of this Agreement, any Order, or any SOW, the
              whole Agreement, or the relevant Order, or SOW, shall be regarded
              null and invalid.
            </p>
            <p>
              <strong>7.6.Governing Law:</strong> This Agreement is governed by
              the laws of Florida, without regard to its conflicts of law rules,
              and each party hereby consents to exclusive jurisdiction and venue
              in the state and federal courts in Florida, for any dispute
              arising out of this Agreement.
            </p>
            <p>
              <strong>7.7. Revisions: </strong> O-Mail retains the right to
              change this Agreement at any time by publishing a revised version
              on the Site, which will take effect five (5) days after it is
              posted. Customer's acceptance of the amended Agreement will be
              determined by continued usage of the Services after the revision's
              effective date. If Customer disagrees to the adjustments, Customer
              may cancel any Orders covered by this Agreement by giving O-Mail
              written notice prior to the revision's effective date, and
              Customer will remain liable to pay any sums payable to O-Mail
              under such Orders without receiving a return of any prepaid fees.
              Customer's termination will be effective upon O-Mail's written
              acknowledgement of such termination, and in no event later than
              thirty (30) days from O-Mail's receipt of Customer's termination
              notice.
            </p>
          </li>
        </ol>
      </div>

      <div class="omail-agreement-data-form mb-5 mt-3 py-4 px-5">
        <form [formGroup]="userAgreementForm" (ngSubmit)="saveAgreement()">
          <div class="row align-items-center justify-content-md-between">
            <div class="col-md-12">
              <div class="form-check text-left mb-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="acceptTerms1"
                  [ngClass]="{
                    'is-invalid':
                      agrrementSubmitted && agreementF.acceptTerms1.errors
                  }"
                />
                <label
                  class="form-check-label pl-3"
                  style="color: black !important"
                >
                  <p>
                    BY CLICKING A BOX INDICATING ACCEPTANCE OF, OR EXECUTING AN
                    ORDER THAT INCORPORATES, THIS USER AGREEMENT (“Agreement”),
                    THE INDIVIDUAL OR LEGAL ENTITY (“Founder/Customer”) AGREES
                    THE TERMS AND CONDITIONS OF THIS AGREEMENT GOVERN CUSTOMER’S
                    ACCESS TO AND USE OF THE SERVICES PROVIDED BY O-MAIL.
                    ("O-MAIL"). THIS AGREEMENT IS EFFECTIVE AS OF THE DATE OF
                    SUCH ACCEPTANCE OR EXECUTION (“Effective Date”). IF ANY
                    TERMS OR CONDITIONS IN THE O-MAIL AGREEMENT SUPPLEMENT APPLY
                    TO CUSTOMER OR A SPECIFIC  SERVICE, THOSE TERMS AND
                    CONDITIONS ARE ALSO INCORPORATED HEREIN BY REFERENCE AND
                    FORM PART OF THIS AGREEMENT.
                  </p>
                  <p>
                    ANY INDIVIDUAL AGREEING TO BE BOUND BY THIS AGREEMENT ON
                    BEHALF OF A LEGAL ENTITY REPRESENTS THAT SUCH INDIVIDUAL HAS
                    THE AUTHORITY TO BIND SUCH ENTITY TO THE TERMS AND
                    CONDITIONS CONTAINED HEREIN.
                  </p>
                  <p>
                    FOUNDER/CUSTOMER SHALL NOT ACCESS OR USE THE SERVICES
                    WITHOUT PRIOR WRITTEN CONSENT OF O-MAIL IF FOUNDER/CUSTOMER
                    IS OR BECOMES A DIRECT COMPETITOR TO O-MAIL OR ITS
                    AFFILIATES.
                  </p>
                </label>
                <div
                  *ngIf="agrrementSubmitted && agreementF.acceptTerms1.errors"
                  class="invalid-feedback"
                >
                  Terms & Conditions are required
                </div>
              </div>
              <div class="form-check text-left mt-4">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="acceptTerms2"
                  [ngClass]="{
                    'is-invalid':
                      agrrementSubmitted && agreementF.acceptTerms2.errors
                  }"
                />
                <label
                  class="form-check-label pl-3"
                  style="color: black !important"
                  >I CONFIRMED THAT I
                  <strong class="text-uppercase">{{
                    founderName
                  }}</strong>
                  AND / OR AN AUTHORISED PERSON TO MANAGE THIS ACCOUNT.</label
                >
                <div
                  *ngIf="agrrementSubmitted && agreementF.acceptTerms2.errors"
                  class="invalid-feedback"
                >
                  Confirmation is required
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-4 text-left">
              <div class="agree-date">
                <p class="p-0 pr-3 mb-3">Signature:</p>
                <input
                  type="text"
                  matInput
                  class="form-control w-75"
                  formControlName="userName"
                  (keypress)="alphaNumericSpecCharEvent($event)"
                  [ngClass]="{
                    'is-invalid':
                      agrrementSubmitted && agreementF.userName.errors
                  }"
                />
                <div
                  *ngIf="
                    agrrementSubmitted &&
                    userAgreementForm.controls['userName'].invalid
                  "
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="
                      userAgreementForm.controls['userName'].errors.required
                    "
                  >
                    Signature is required
                  </div>
                  <div *ngIf="agreementF.userName.errors.minlength">
                    Signature should be min 3 character
                  </div>
                  <div *ngIf="agreementF.userName.errors.maxlength">
                    Signature should be max 50 characters
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-4 text-right">
              <div
                class="
                  agree-date
                  mb-3
                  d-flex
                  justify-content-end
                  align-items-center
                "
              >
                <label for="staticEmail" class="col-auto p-0 pr-3 mb-0"
                  >Date:
                </label>
                <p class="date_txt text-right mb-0" id="staticEmail">
                  {{ acceptDate | date: "MMM d, y h:mm a" }}
                </p>
              </div>
              <button class="custom-btn-outline mr-3" type="reset">
                Decline
              </button>
              <button type="submit" class="custom-btn-fill">
                <em
                  class="fa fa-circle-o-notch fa-spin"
                  *ngIf="showSpinner"
                ></em>
                Accept
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- omail agreement end  -->
</div>
