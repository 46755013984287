import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[voidUnusedDataInPaste]"
})
export class AvoidUnusedDataInPasteDirective {
  constructor(private elRef: ElementRef) {}

  @HostListener("paste", ["$event"])
  onPaste(event) {
    this.elRef.nativeElement.value = event.clipboardData.getData("text/plain");
    this.elRef.nativeElement.value = this.elRef.nativeElement.value
      .replace(/\s+/gi, "")
      .replace(/\W+/gi, "");

    event.preventDefault();
    event.stopPropagation();
  }
}
