import {
  AbstractControl,
  ControlContainer,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SPL_CHAR, SPECIAL_CHARACTERS } from 'src/app/util/constants';

export class CustomValidator {
  registerForm: ControlContainer;
  constructor(private form: ControlContainer) {
    this.registerForm = this.form;
  }
  static cannotContainConsecutiveSpaces(
    control: AbstractControl
  ): ValidationErrors | null {
    if ((control.value as string)?.indexOf('  ') !== -1) {
      event.stopPropagation();
      return { containConsecutiveSpaces: true };
    }

    control.setErrors({ containConsecutiveSpaces: null });
    return null;
  }

  static cannotContainConsecutiveDots(
    control: AbstractControl
  ): ValidationErrors | null {
    if ((control.value as string)?.indexOf('..') !== -1) {
      event.stopPropagation();
      return { containConsecutiveDots: true };
    }

    control.setErrors({ containConsecutiveDots: null });
    return null;
  }

  static cannotContainConsecutiveHyphens(
    control: AbstractControl
  ): ValidationErrors | null {
    if ((control.value as string)?.indexOf('--') !== -1) {
      event.stopPropagation();
      return { containConsecutiveHyphens: true };
    }
    control.setErrors({ containConsecutiveHyphens: null });
    return null;
  }

  static cannotContainConsecutiveUnderScores(
    control: AbstractControl
  ): ValidationErrors | null {
    if ((control.value as string)?.indexOf('__') !== -1) {
      event.stopPropagation();
      return { containConsecutiveUnderScores: true };
    }
    control.setErrors({ containConsecutiveUnderScores: null });
    return null;
  }

  static requiredIfValidator(predicate) {
    return (formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  // checking whether form control value contain only numbers
  static cannotContainOnlyNumbers(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = (control.value as string)?.trim();
    if (value && !isNaN(+value)) {
      // event.stopPropagation();
      return { containOnlyNumbers: true };
    }
    control.setErrors({ containOnlyNumbers: null });
    return null;
  }

  // checking whether form control value contain only special characters
  static cannotContainOnlySpecialCharacters(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = (control.value as string)?.trim();
    const result =
      value &&
      [...value].every(
        (ele) => [...SPL_CHAR, ...SPECIAL_CHARACTERS].indexOf(ele) > -1
      );
    if (result) {
      return { containOnlySpecialChars: true };
    }
    control.setErrors({ containOnlySpecialChars: null });
    return null;
  }

  // checking whether form control value contain only some special characters for password
  static canContainSomeSpeciacharacters(
    control: AbstractControl
  ): ValidationErrors | null {
    const patternSpecial = /^[0-9a-zA-Z\!@#$%\\^&*()\-+=/]+$/;
    const value = patternSpecial.test(control.value as string);
    if (!value) {
      return { containRestrictedSpecificChars: true };
    }
    control.setErrors({ containRestrictedSpecificChars: null });
    return null;
  }

  static checkAllEqual(control: AbstractControl): ValidationErrors | null {
    const value = control.value.split('');
    const checkAllVal = value.every((val) => val === value[0]);
    if (checkAllVal) {
      return { isEqual: true };
    }
    control.setErrors({ isEqual: false });
    return null;
  }

  static getNameValidators(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(50),
      this.cannotContainOnlyNumbers,
      this.cannotContainOnlySpecialCharacters,
      this.cannotContainConsecutiveSpaces,
      this.cannotContainConsecutiveDots,
      this.cannotContainConsecutiveHyphens,
      this.cannotContainConsecutiveUnderScores,
    ]);
  }

  static getEmailValidators(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      Validators.email,
      Validators.maxLength(100),
      Validators.pattern('[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]);
  }

  static getPhoneNumberValidators(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
      Validators.pattern('^[0-9]+$'),
      CustomValidator.checkAllEqual, // checking continuous numbers OFD-822
    ]);
  }
}
