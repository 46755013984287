import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebserviceService } from 'src/app/shared/services/webservice.service';
import { GET } from 'src/app/util/constants';

@Component({
  selector: 'app-invoice-link',
  templateUrl: './invoice-link.component.html',

})
export class InvoiceLinkComponent implements OnInit {
  constructor(
    private router: Router,
    private aRoute: ActivatedRoute,
    private _service: WebserviceService
  ) {}

  ngOnInit() {
    const id = this.aRoute.snapshot.queryParams['hash'];
    if (id == null) {
      this.router.navigateByUrl('/auth/login');
  
    } else {
      const params = {
        responseType: 'blob',
      };
      this._service
        .commonMethod(`payment/pdfInvoice?hash=${id}`, params, GET)
        .subscribe(
          (res) => {
            const i = res.type.indexOf('/');
            const name = 'Invoice.' + (res.type + ' ').slice(i + 1, -1);
            saveAs(res, name);
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }
}
