import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customDate"
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(
    value: Date,
    timeZone: string,
    includeDay?: string,
    shouldPresentOn?: string,
    dayWide?: string
  ): any {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    const monthsShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const givenDate = this.convertTZ(value, timeZone);
    const year = givenDate.getFullYear();
    const date = givenDate.getDate();
    const month = dayWide
      ? monthsShort[givenDate.getMonth()]
      : months[givenDate.getMonth()];

    let format = `h:mm a`;
    if (includeDay === "day") {
      format = `h:mm a, ${dayWide ? "EEE" : "EEEE"}`;
    }
    let dayFormat = super.transform(givenDate, format);
    if (includeDay === "day" && shouldPresentOn === "on") {
      dayFormat = dayFormat.replace(",", " on");
    }

    return `${dayFormat}, ${month} ${date}${this.nth(date)}, ${year}`;
  }

  nth(d: number) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }
}
