import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[restrictPaste]',
})
export class RestrictPasteDirective {
  selectedText;
  constructor(private el: ElementRef) {}

  @HostListener('paste', ['$event']) blockPaste(event) {
    setTimeout(() => {
      event.preventDefault();
      this.selectedText = this.el.nativeElement.value
        .replace(/['"*<>/\\]/g, '')
        .trim();
      this.el.nativeElement.value = '';
      document.execCommand('insertText', false, this.selectedText);
    }, 100);
  }
}
