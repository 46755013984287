import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { WebserviceService } from './webservice.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenCache: Observable<any>;
  private readonly CACHE_SIZE = 1;
  private readonly REFRESH_INTERVAL = 55 * 60 * 1000; // 55min refresh token time
  userToken: string;
  private tokenSubscription: Subscription;
  private timer: Subject<boolean>;

  constructor(
    private _service: WebserviceService,
    private _ls: LocalStorageService
  ) {}

  set token(token: string) {
    this.userToken = token;
    this._ls.setLoggedInUser('user', token, true);
    if (token && !this.tokenCache) {
      const stopTimer = new Subject<boolean>();
      this.timer = stopTimer;
      const myTimer = timer(this.REFRESH_INTERVAL, this.REFRESH_INTERVAL);
      this.tokenCache = myTimer.pipe(
        takeUntil(stopTimer),
        switchMap(() => this.refreshToken()),
        shareReplay(this.CACHE_SIZE)
      );
      this.tokenSubscription = this.tokenCache.subscribe(
        (res) => {
          this.userToken = res.jwt;
          this._ls.setLoggedInUser('user', this.userToken, true);
          this._ls.setLoggedInUser('userEmail', res.userEmail, true);
          this._ls.setLoggedInUser('userDetails', res, true);
        },
        (err) => {
          console.error(err);
          this.clear();
        }
      );
    }
  }

  private refreshToken() {
    // console.info('Refresh token requested in Token service');
    return this._service.commonMethod('refreshToken', null, 'GET');
  }

  clear() {
    if (this.tokenSubscription) {
      this.tokenSubscription.unsubscribe();
    }
    if (this.timer) {
      this.timer.next(true);
      this.timer = null;
    }

    this.tokenCache = null;
    this.userToken = null;
    this._service.logout();
  }
}
