import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.scss'],
})
export class LegalDisclaimerComponent implements OnInit {
  @ViewChild('okBtn') okBtn: ElementRef;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    setTimeout(() => {
      this.okBtn.nativeElement.focus(), 1000;
    });
  }

  agreedTermsAndConditions(isAgreed: boolean) {
    this.activeModal.close(isAgreed);
  }
}
