import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { GET, POST } from 'src/app/util/constants';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class WebserviceService {
  apiUrl = environment.apiUrl;
  trimUrl = environment.trimUrl;
  supportApiUrl = environment.supportApiEndPoint;
  otrackerApiUrl = environment.oTrackerIpUrl;
  cloudFrontUrl = environment.cloudFrontUrl;
  userProfileDetails!: any;
  ebookUrlToPreveiw = '';
  myLinkToShare = '';
  myLinkSelectedTabName = '';
  myLinkSelectedTabIndex = 1;
  myInviteeInfo: { firstName: string; lastName: string; email: string };
  updatesDataInReadMore = '';
  ckeConfig: any;
  selectedGroupId = new Subject<number>();
  childFounderId: number;
  dynamicComponentNotifier = new Subject<string>();
  httpClientBackend: HttpClient;

  spamWordsList1 = [
    '$',
    'cash',
    'dollar',
    'price',
    'winner',
    'pay',
    'credit',
    'sale',
    'buy',
    'money',
    'marketing',
  ];

  spamWordsList2 = [
    'mass',
    'spam',
    'limited',
    'Millions',
    'guarantee',
    'offer',
    'paid',
    'refund',
    'income',
    'free',
    'junk',
  ];

  spamWordsList3 = [
    'card',
    'dollars',
    'urgent',
    'debit',
    'unlimited',
    'earn',
    'deal',
    'instant',
    'consultation',
    'opportunity',
    'investment',
  ];

  spamWordsList4 = [
    'leads',
    'refinance',
    'quotes',
    'risk',
    'satisfaction',
    'save$',
    '!!!',
    '!!',
    'babes',
    'wining',
    'winner',
  ];

  spamWordsList5 = [
    'security',
    'special',
    'stock',
    'alert',
    'disclaimer',
    'statement',
    'shopping',
    '100%',
    'automation',
    'handsfree',
    'cost',
  ];

  spamWordsList6 = [
    '4u',
    'amazing',
    'additional',
    'hesitate',
    'amazed',
    'bucks',
    'bankruptcy',
    'percent',
    'earnings',
    'profits',
    'guaranteed',
  ];

  spamWordsList7 = [
    'investment',
    'wrinkles',
    'biz',
    'percent',
    'lifetime',
    'extra',
    'obligation',
    'gimmick',
    'disappointment',
    'claim',
    'multi',
  ];

  spamWordsList8 = [
    'billion',
    'creditors',
    'congratulations',
    'offers',
    'bureaus',
    'direct',
    'drastically',
    'easy',
    'fantastic',
    'deal',
    'leads',
  ];

  spamWordsList9 = [
    'freedom',
    'membership',
    'snoring',
    'promotion',
    'security',
    'believe',
    'action',
    'compliance',
    'automated',
  ];

  timeZoneDetails: { country: string; city: string; timeZone: string };
  timeZone!: string;

  otpConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
    inputClass: 'otpInput',
    containerClass: 'otpContainer',
  };

  constructor(
    private _http: HttpClient,
    private router: Router,
    private _ls: LocalStorageService,
    private httpBackend: HttpBackend,
    private utilService: UtilService,
    private matDialog: MatDialog,
    private modalDialog: NgbModal
  ) {
    this.httpClientBackend = new HttpClient(this.httpBackend);
  }

  refreshToken() {
    this.commonMethod('refreshToken', null, GET).subscribe(
      (result: any) => {
        this._ls.setLoggedInUser('user', result.jwt, true);
        this._ls.setLoggedInUser('userEmail', result.userEmail, true);
        this._ls.setLoggedInUser('userDetails', result.jwtTokenDetails, true);
        sessionStorage.setItem(' _uxid',result.jwtTokenDetails['paymentStatus']);
        this.router.navigate(['/dashboard']);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  commonMethod(
    url: string,
    data?: any,
    method?: string,
    options?: any,
    url_type?: any
  ): Observable<any> {
    const endPoint = url_type ? `${url_type}${url}` : `${this.apiUrl}${url}`;
    const body = data || '';
    const reqOptions = options || '';

    switch (method) {
      case 'POST':
        return this._http.post(endPoint, body, reqOptions);
      case 'PUT':
        return this._http.put(endPoint, body, reqOptions);
      case 'DELETE':
        return this._http.delete(endPoint, body);
      default:
        return this._http.get(endPoint, body);
    }
  }

  getEndPointArray(options: any, ...endPoints: string[]) {
    const endPointsArray = [];
    for (const endPoint of endPoints) {
      if (options) {
        endPointsArray.push(
          this._http.get(`${this.apiUrl}${endPoint}`, options)
        );
      } else {
        endPointsArray.push(this._http.get(`${this.apiUrl}${endPoint}`));
      }
    }
    return endPointsArray;
  }

  downloadCsv(
    data: any,
    type: string,
    fileName: string,
    header: string[],
    headerDisplayData?: string[]
  ) {
    const replacer = (key: string, value: string) =>
      value === null ? '' : value; // specify how you want to handle null values here

    headerDisplayData = headerDisplayData || header;
    const dataSource = data;
    const csv = dataSource.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(headerDisplayData.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type });
    saveAs(blob, fileName);
  }

  logout() {
    if (this._ls.isLoggedIn()) {
      this.commonMethod('expire-token').subscribe();
      this._ls.removeUser('user');
      this._ls.removeUser('userDetails');
      this._ls.removeUser('userEmail');
      // this._ls.removeUser('isMcpPageAccessed');
      // this._ls.removeUser('showAnnouncement');
      this._ls.removeUser('isRedirectedToPaymentGateway');
      localStorage.clear();
      this._ls.setUserInfo(null);
      this.router.navigate(['/auth/login']);
    }else{
      this._ls.removeUser('userDetails');
      this._ls.removeUser('userEmail');
      localStorage.clear();
      sessionStorage.clear();
      this._ls.setUserInfo(null);
      this.router.navigate(['/auth/login']);
    }
  }

  getCkEditorConfig() {
    return {
      uiColor: '#C233FF',
      allowedContent: false,
      forcePasteAsPlainText: true,
      font_names: 'Arial;Times New Roman;Verdana',
      toolbarLocation: 'bottom',
      toolbarGroups: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'] },
        {
          name: 'editing',
          groups: ['find', 'selection', 'spellchecker', 'editing'],
        },
        { name: 'forms', groups: ['forms'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        {
          name: 'paragraph',
          groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
        },
        { name: 'links', groups: ['links'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
        { name: 'tools', groups: ['tools'] },
        { name: 'others', groups: ['others'] },
        { name: 'about', groups: ['about'] },
      ],
      smiley_path: '/assets/user/images_v1/reactions_icons/png/',
      smiley_columns: 10,
      smiley_images: [
        'like.png',
        'love.png',
        'haha.png',
        'wow.png',
        'sad.png',
        'angry.png',
        'cowboy.png',
        'glasses.png',
        'nerd.png',
        'party.png',
        'hand_chill.png',
        'horns.png',
        'sleeping.png',
        'cry.png',
        'hot_face.png',
        'cold_face.png',
        'money.png',
        'raising_hands.png',
        'yawning_face.png',
        'pointing_up.png',
        'zipper_mouth.png',
        'sneezing_face.png',
        'crossed_fingers.png',
        'head_bandage.png',
        'face_hearts.png',
        'face_tongue.png',
        'flexed_biceps.png',
        'halo.png',
        'handshake.png',
        'lying_face.png',
        'pleading_face.png',
        'rolling_eye.png',
        'smirking_face.png',
        'star_struck.png',
        'unamused_face.png',
        'monocle.png',
        'blowing_kiss.png',
        'clapping_hands.png',
        'drooling_face.png',
        'exploding_head.png',
        'face_with_thermometer.png',
        'floor_laughing.png',
        'flushed_face.png',
        'frowning_face.png',
        'hugging_face.png',
        'hushed_face.png',
        'index_pointing_down.png',
        'index_pointing_left.png',
        'index_pointing_right.png',
        'middle_finger.png',
        'oncoming_fist.png',
        'pleading_face_1.png',
        'pouting_face_1.png',
        'pouting_face_2.png',
        'relived_face.png',
        'screaming_in_fear.png',
        'shushing_face.png',
        'smiling_eyes.png',
        'smiling_eyes_2.png',
        'squinting_face.png',
        'symbols_on_mouth.png',
        'thinking_face.png',
        'thumbs_down.png',
        'upside_down_face.png',
        'waving_hand.png',
        'weary_face.png',
        'winking_face.png',
        'woozy_face.png',
        'yawning_face_1.png',
        'zany_face.png',
      ],
      smiley_descriptions: [
        'O-Like',
        'O-Love',
        'O-Haha',
        'O-Wow',
        'O-Sad',
        'O-Angry',
        'O-Cowboy',
        'O-Glasses',
        'O-Nerd',
        'O-Party',
        'O-Chill',
        'O-Horns',
        'O-Sleeping',
        'O-Cry',
        'O-HotFace',
        'O-ColdFace',
        'O-Money',
        'O-RaisingHands',
        'O-YawningFace',
        'O-PointingUp',
        'O-ZipperMouth',
        'O-SneezingFace',
        'O-CrossedFingers',
        'O-Bandage',
        'O-FaceHearts',
        'O-FaceTongue',
        'O-Biceps',
        'O-Halo',
        'O-Handshake',
        'O-LyingFace',
        'O-PleadingFace',
        'O-RollingEye',
        'O-SmirkingFace',
        'O-StarStruck',
        'O-UnamusedFace',
        'O-Monocle',
        'O-BlowingKiss',
        'O-ClappingHands',
        'O-DroolingFace',
        'O-ExplodingHead',
        'O-Thermometer',
        'O-Laughing',
        'O-FlushedFace',
        'O-FrowningFace',
        'O-HuggingFace',
        'O-HushedFace',
        'O-PointingDown',
        'O-PointingLeft',
        'O-PointingRight',
        'O-MiddleFinger',
        'O-OncomingFist',
        'O-PleadingFaceOne',
        'O-PoutingFaceOne',
        'O-poutingFaceTwo',
        'O-RelivedFace',
        'O-ScreamingInFear',
        'O-ShushingFace',
        'O-SmilingEyes',
        'O-SmilingEyesTwo',
        'O-SquintingFace',
        'O-SymbolsOnMouth',
        'O-ThinkingFace',
        'O-ThumbsDown',
        'O-UpsideDownFace',
        'O-WavingHand',
        'O-WearyFace',
        'O-WinkingFace',
        'O-WoozyFace',
        'O-YawningFaceOne',
        'O-ZanyFace',
      ],
      removePlugins: 'elementspath,save,magicline',
      removeButtons:
        'Print,Templates,Save,NewPage,Preview,Paste,Replace,Find,SelectAll,Form,Checkbox,Radio,Textarea,TextField,Select,Button,ImageButton,HiddenField,CreateDiv,Image,Flash,PageBreak,Iframe,HorizontalRule,BidiRtl,BidiLtr,ShowBlocks,Cut,Copy,PasteText,PasteFromWord,Outdent,Indent,Blockquote,Language,Anchor,Table,Subscript,Superscript,Maximize,About,Scayt,SpecialChar,Styles',
    };
  }

  imageUrl: any;
  onPhotoChanged(
    event: any,
    fileName: string,
    fileFormats: string[],
    maxSize = 2000,
    skipSizeValidation?: boolean
  ) {
    if (event.target.files && event.target.files[0]) {
      const selectedPhoto = event.target.files[0];
      if (fileFormats.indexOf(selectedPhoto.type) > -1) {
        if (selectedPhoto.size / 1024 <= maxSize || skipSizeValidation) {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent) => {
            this.imageUrl = (<FileReader>event.target).result;
          };
          reader.readAsDataURL(event.target.files[0]);
        } else {
          this.utilService.showWarningMessage(
            `Uploaded file exceeds maximum size of ${maxSize / 1000}MB`
          );
          return { uploadImageData: null, imageUrl: null };
        }

        const uploadImageData = new FormData();
        uploadImageData.append(fileName, selectedPhoto, selectedPhoto.name);
        return { uploadImageData, imageUrl: this.imageUrl };
      } else {
        const allowedFileTypes = fileFormats.map((type) => {
          const fileType = type.split('/')[1].toUpperCase();
          switch (fileType) {
            case 'text/plain':
              return 'TXT';
            case 'image/svg+xml':
              return 'SVG';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'DOCX';
            default:
              return fileType;
          }
        });
        Swal.fire(
          'Please upload valid file format',
          `Only ${allowedFileTypes} file types are allowed`,
          'warning'
        );
        return { uploadImageData: null, imageUrl: null };
      }
    }
    return { uploadImageData: null, imageUrl: null };
  }

  findSpamWords(sourceText: string) {
    const alteredSpamWordsList1 = [...this.spamWordsList1];
    alteredSpamWordsList1.shift(); // removing '$' from the list as regex unable to find it directly
    const spamWords = [
      ...alteredSpamWordsList1,
      ...this.spamWordsList2,
      ...this.spamWordsList3,
      ...this.spamWordsList4,
      ...this.spamWordsList5,
      ...this.spamWordsList6,
      ...this.spamWordsList7,
      ...this.spamWordsList8,
      ...this.spamWordsList9,
    ];

    let isSpamWordPresent = false;

    const spamText = sourceText
      .trim()
      .replace(
        new RegExp('\\$|'.concat(spamWords.join(`\\b|\\b`)), 'gi'),
        (match) => {
          if (match !== '') {
            isSpamWordPresent = true;
            return '<span style="color:red">' + match + '</span>';
          } else {
            return '';
          }
        }
      );

    return { spamText, isSpamWordPresent };
  }

  getSpamWordsList() {
    return [
      this.spamWordsList1,
      this.spamWordsList2,
      this.spamWordsList3,
      this.spamWordsList4,
      this.spamWordsList5,
      this.spamWordsList6,
      this.spamWordsList7,
      this.spamWordsList8,
      this.spamWordsList9,
    ];
  }

  setTimeZoneDetails(data) {
    this.timeZoneDetails = { ...data };
  }

  downloadFileFromS3Bucket(path: string) {
    const filePath = decodeURIComponent(path); // decode in case path contains spaces, ( and )
    const file = filePath.split('/')[filePath.split('/').length - 1];
    const fileType = file.split('.')[filePath.split('.').length - 1];
    const fileName = file.split('.')[0];
    this.commonMethod('file/download', { fileName: filePath }, POST, {
      responseType: 'blob',
    }).subscribe((res) => {
      const name = `${fileName}.${fileType}`;
      saveAs(res, name);
    });
  }

  registerToOTracker(api: string, requestBody, options?: unknown) {
    return this.httpClientBackend.post(
      `${environment.OFounderOTrackerIpUrl}${api}`,
      requestBody,
      options
    );
  }

  closeAllPopups() {
    this.matDialog.closeAll();
    if (this.modalDialog.hasOpenModals()) {
      this.modalDialog.dismissAll();
    }
  }

  resetPassword(payload, eventEmitter?: any) {
    return this.commonMethod(
      'validate-reset-password-otp',
      payload,
      POST
    ).subscribe((res) => {
      if (res && res.httpStatus === 'OK' && res.message === 'SUCCESS') {
        Swal.fire(
          'Your Password Is Reset, Please Check Inbox Of Your Registered Email!!',
          '',
          'success'
        ).then(
          (res) => {
            eventEmitter.next();
            this.dynamicComponentNotifier.next('login');
          } //this.router.navigate(['/auth/login'])
        );
      } else {
        if (res.statusMsg === 'FAIL') {
          // Swal.fire('Invalid Access Code', '', 'warning');
          Swal.fire(res.exMsg, '', 'warning');
        }
      }
    });
  }

  submitInviteForm(inviteForm: FormGroup, invitee: string, linkNumber: number) {
    this.router.navigateByUrl('/registration/user');

    // if (inviteForm.invalid) {
    //   return;
    // }

    // const formModel = inviteForm.value;
    // const payload = {
    //   emailId: formModel.email,
    //   encryptedReferralId: invitee,
    //   firstName: formModel.firstName,
    //   lastName: formModel.lastName,
    //   linkNumber,
    // };
    // this.commonMethod(SAVE_INVITE_ENDPOINT, payload, 'POST').subscribe(
    //   (data) => {
    //     if (data && data.message === 'SUCCESS') {
    //       Swal.fire('Thank you for requesting invitation', '', 'success').then(
    //         (res) => {
    //           inviteForm.reset();
    //         }
    //       );
    //     } else if (data.message === 'Already Invited') {
    //       Swal.fire('Email already invited', '', 'error');
    //     } else if (data.message === 'FAIL') {
    //       console.info(data.details);
    //       Swal.fire(
    //         `Invalid email address: ${formModel.emailId}`,
    //         '',
    //         'warning'
    //       );
    //     }
    //   },
    //   (err: any) => {
    //     const error = err.error;
    //     if (error.message === 'FAIL' && error.details[0] === 'ALREADY_EXISTS') {
    //       Swal.fire('This email is already registered with us', '', 'error');
    //     } else {
    //       Swal.fire('Error while submitting the form', '', 'error');
    //     }
    //   }
    // );
  }

  // regenerateCaptcha() {
  //   this.registerForm.controls['enteredCaptcha'].reset();

  //   this.captchaService.reloadCaptcha(this.captchaId).subscribe((res) => {
  //     this.captchaId = res.captchaId;
  //     this.imageCaptcha = res.imageCaptcha;
  //   });
  // }

  validateEmailAndDoRegistration(email: string, requestBody) {
    this.commonMethod(`checkEmailId/${email}`, null, GET).subscribe((res) => {
      if (res && res.message === 'Already Existed') {
        Swal.fire(`Account already exists with this email id`, '', 'warning');
        // this.regenerateCaptcha();
        return;
      } else {
        this.commonMethod('signup', requestBody, 'POST').subscribe(
          (result) => {
            if (result.httpStatus !== 'OK') {
              Swal.fire('Error while doing registration', '', 'error');
              console.error(result.message);
              // this.regenerateCaptcha();
              return;
            }
            Swal.fire(
              'Registration done successfully',
              'Please login to continue',
              'success'
            ).then(() => {
              this.router.navigate(['/login']);
              return;
            });
          },
          () => {
            // this.regenerateCaptcha();
            Swal.fire('Error while doing registration', '', 'error');
          }
        );
      }
    });
  }

  doRegistrationForPresentationLinks(
    formData: FormGroup,
    sponserUserId,
    encryptSponId
  ) {
    this.router.navigateByUrl('/registration/user');

    // if (formData.invalid) {
    //   return;
    // }

    // const formModel = formData.value;
    // const email = formModel.email;

    // const requestBody = {
    //   userId: 0,
    //   countryId: +formModel.country_id,
    //   email: email,
    //   firstName: formModel.firstname,
    //   lastName: formModel.lastname,
    //   userName: formModel.firstname + ' ' + formModel.lastname,
    //   phone: formModel.phone,
    //   password: formModel.password,
    //   sponserUserId,
    //   encryptSponId,
    // };

    // this.validateEmailAndDoRegistration(email, requestBody);
  }

  saveBookedWebinar(webinarForm: FormGroup, userId: string, url: string) {
    const req = {
      emailId: webinarForm.value.email,
      firstName: webinarForm.value.firstName,
      weinarurl: url,
      lastName: webinarForm.value.lastName,
      userId: userId,
    };

    this.commonMethod(`checkEmailId/${req.emailId}`, null, GET).subscribe(
      (res) => {
        if (res && res.message === "Email Doesn't Exist") {
          Swal.fire(`Invalid email address: ${req.emailId}`, '', 'warning');
        } else {
          this.commonMethod(
            'webinar/saveOrUpdateBookedWebinar',
            req,
            'POST',
            ''
          ).subscribe(
            (result: any) => {
              if (result && result.message === 'SUCCESS') {
                webinarForm.reset();
                $('#open-modal').css('display', 'none');
                this.utilService.showSuccessMessage(
                  '',
                  'Your invitation has been sent Successfully. Please check your mail and follow the steps.'
                );
              } else {
                this.utilService.showWarningMessage(
                  'Error while submitting the form'
                );
              }
            },
            (err: any) => {
              if (err.message === 'invited') {
                this.utilService.showWarningMessage('Email already invited');
              } else {
                this.utilService.showWarningMessage(
                  'Error while submitting the form'
                );
              }
            }
          );
        }
      }
    );
  }

  updateFounderProfileInODesk(founderDetails: any) {
    const payload = {
      region: '',
      userCountry: founderDetails.userCountry,
      userEmail: founderDetails.userEmail,
      userFName: founderDetails.userFName,
      userId: founderDetails.userId,
      userLName: founderDetails.userLName,
      userPhoneNo: founderDetails.userPhoneNo,
      userProfilePic: this.cloudFrontUrl + founderDetails.userFile,
    };
    this.httpClientBackend
      .post(`${this.supportApiUrl}updateGoFounderDetail`, payload)
      .subscribe();
  }

  getFounderProfileDetails() {
    return this.commonMethod(`profile/getUserProfileDetails`, '', GET).pipe(
      retry(1),
      catchError(() => EMPTY)
    );
  }
}
