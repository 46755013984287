import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
//OTRIM IMPORTS ====================================>
import { QRCodeModule } from 'angularx-qrcode';
//ONET IMPORTS==============================================>
import { CarouselModule } from 'ngx-owl-carousel-o';
//ONET IMPORTS==============================================>
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnimatedCountComponent } from './components/animated-count/animated-count.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AgreementsComponent } from './modules/o-mail/agreements/agreements.component';
import { EncrDecrService } from './shared/services/encr-decr.service';
import { CommonPipesModule } from './_helper/pipes/common-pipes.module';
import { ApiInterceptor } from './_interceptors/api.interceptor';
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import 'froala-editor/js/plugins.pkgd.min.js';
@NgModule({
  declarations: [
    AppComponent,
    AgreementsComponent,
    // ChatbotComponent,
    // FaqsComponent,
    VerifyEmailComponent,
    AnimatedCountComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    AutocompleteLibModule,
    SlickCarouselModule,
    NgxSpinnerModule,

    JwtModule.forRoot({
      config: { throwNoTokenError: true, skipWhenExpired: true },
    }),
    CommonPipesModule,
    LoadingBarHttpClientModule,
    CarouselModule,
    QRCodeModule,
    FroalaEditorModule.forRoot(), 
    FroalaViewModule.forRoot(),
  ],
  providers: [
    EncrDecrService,
    BreadcrumbService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
