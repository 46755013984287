import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[avoidSplChar]',
})
export class AcceptSplCharDirective {
  constructor(private el: ElementRef) {}
  @HostListener('keypress', ['$event']) onKeypress(event: KeyboardEvent) {
    const inputVal = this.el.nativeElement.value;
    inputVal.indexOf('  ') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('..') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('--') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('__') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('*') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('<') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('>') !== -1
      ? event.preventDefault()
      : inputVal.indexOf("'") !== -1
      ? event.preventDefault()
      : inputVal.indexOf('"') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('/') !== -1
      ? event.preventDefault()
      : inputVal.indexOf('\\') !== -1
      ? event.preventDefault()
      : this.el.nativeElement.selectionStart === 0 && event.key === ' '
      ? event.preventDefault()
      : event.stopPropagation();
  }
}
