import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { PaymentErrorComponent } from 'src/app/modules/payments/payment-error/payment-error.component';
import { POST } from 'src/app/util/constants';
import { LocalStorageService } from '../services/local-storage.service';
import { WebserviceService } from '../services/webservice.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentResolver implements Resolve<boolean> {
  constructor(
    private _service: WebserviceService,
    private _ls: LocalStorageService,
    private modalService: NgbModal
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (state.url === '/payment/payment-error') {
      this.modalService.open(PaymentErrorComponent, {
        backdrop: 'static',
      });
      return of(false);
    }

    const isRedirectedToPaymentGateway = this._ls.getLoggedInUser(
      'isRedirectedToPaymentGateway',
      true
    );

    if (isRedirectedToPaymentGateway === 'true') {
      this._ls.removeUser('isRedirectedToPaymentGateway');
      const tokenDetails = this._ls.getLoggedInUser('userDetails', true);

      return this._service.commonMethod('get-token', tokenDetails, POST);
    }

    return of(null);
  }
}
