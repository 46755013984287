import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Utf8EmojisToImagesPipe } from 'ng-utf8-emojis-to-images';
import { AcceptSplCharDirective } from 'src/app/shared/directives/accept-spl-char.directive';
import { AlphabetOnlyDirective } from 'src/app/shared/directives/alphabet-only.directive';
import { AvoidUnusedDataInPasteDirective } from 'src/app/shared/directives/avoid-unused-datain-paste.directive';
import { AvoidwhitespacesDirective } from 'src/app/shared/directives/avoidwhitespaces.directive';
import { EmailDirective } from 'src/app/shared/directives/email-only.directive';
import { NavigationControlDirective } from 'src/app/shared/directives/navigation-controls.directive';
import { TwoDigitDecimaNumberDirective } from 'src/app/shared/directives/only.number-decimal.directive';
import { OnlyNumberDirective } from 'src/app/shared/directives/only.number.directive';
import { OnlyCharactersDirective } from 'src/app/shared/directives/only.strings.directive';
import { RestrictPasteDirective } from 'src/app/shared/directives/restrict-paste';
import { CustomDatePipe } from 'src/app/_helper/pipes/custom-date.pipe';
import { HighlightTextPipe } from 'src/app/_helper/pipes/highlight-text/highlight-text.pipe';
import { SafePipe } from 'src/app/_helper/pipes/safe/safe.pipe';
import { ServerFileExistsPipe } from 'src/app/_helper/pipes/server-file-exists/server-file-exists.pipe';
import { TruncatePipe } from 'src/app/_helper/pipes/truncate.pipe';
import { BackgroundUrlPipe } from './background-url.pipe';
import { DateComparisonPipe } from './date-comparison.pipe';
import { FileTypeFinderPipe } from './file-type-finder.pipe';
import { TotalCountPipe } from './total-count.pipe';
import { DateFormatPipe } from './date-format.pipe';

@NgModule({
  declarations: [
    HighlightTextPipe,
    ServerFileExistsPipe,
    SafePipe,
    CustomDatePipe,
    TruncatePipe,
    BackgroundUrlPipe,
    DateComparisonPipe,
    FileTypeFinderPipe,
    RestrictPasteDirective,
    AcceptSplCharDirective,
    AlphabetOnlyDirective,
    EmailDirective,
    AvoidwhitespacesDirective,
    NavigationControlDirective,
    OnlyNumberDirective,
    OnlyCharactersDirective,
    TwoDigitDecimaNumberDirective,
    AvoidUnusedDataInPasteDirective,
    TotalCountPipe,
    DateFormatPipe,
  ],
  imports: [CommonModule],
  exports: [
    HighlightTextPipe,
    ServerFileExistsPipe,
    SafePipe,
    CustomDatePipe,
    TruncatePipe,
    AcceptSplCharDirective,
    AlphabetOnlyDirective,
    EmailDirective,
    AvoidwhitespacesDirective,
    OnlyNumberDirective,
    OnlyCharactersDirective,
    TwoDigitDecimaNumberDirective,
    AvoidUnusedDataInPasteDirective,
    RestrictPasteDirective,

    BackgroundUrlPipe,
    DateComparisonPipe,
    FileTypeFinderPipe,
    TotalCountPipe,
    DateFormatPipe,
  ],
  providers: [SafePipe, ServerFileExistsPipe, Utf8EmojisToImagesPipe],
})
export class CommonPipesModule {}
