// HTTP Methods
export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";

// Login related
export const LOGIN_ENDPOINT = "authenticate";

// Captcha related
export const GETCAPTCHA_ENDPOINT = "api/generateCaptcha";
export const RELOAD_CAPTCHA_ENDPOINT = "api/generateCaptcha";
export const VALIDATE_CAPTCHA_ENDPOINT = "api/validateCaptcha";

export const ONET_WIDGET_ENDPOINT= 'onet/get-sidebar-content';

// Profile related
export const PROFILE_ENDPOINT = "profile/getUserProfileDetails";
// export const SPONSOR_ENDPOINT = "getUserProfileDetails";
export const UPDATE_PROFILE_ENDPOINT = "profile/updateUserProfile";
export const UPDATE_PASSWORD_ENDPOINT = "profile/changePassword";
export const GET_MEDIA_LIBRARY_ENDPOINT = "profile/getMediaLibrary";
export const GET_DEFAULTAVATARS_ENDPOINT = "profile/getDefaultAvatars";
export const POST_REFUND_REQUEST_ENDPOINT = "profile/refundRequest";
export const GET_SENDREFUND_REQUEST_ENDPOINT = "profile/send-otp-for-refund";

// Dashboard related
export const DASHBOARD_ENDPOINT = "user/dashboard";
export const RECENT_USERS_ENDPOINT = "user/recentUsers";
export const TODAY_REGISTEREDUSERS_ENDPOINT = "user/todayRegisteredUsers";
export const CEO_ANNOUNCEMENT_ENDPOINT = "announcements/updates-dashboards";

// Testimonials
export const GET_TESTIMONIALS_ENDPOINT = "testimonial/fetch-testimonials";

// My Invitees
export const GET_MYINVITEES_ENDPOINT = "invitees/scrollPaginationInvitees";
export const DELETE_MYINVITEE_ENDPOINT = "invitees/deleteInvitee";

// My Links
export const GET_SHAREDHISTORY_ENDPOINT = "my-links/fetch-shared-history";

// Webinars
export const GET_UPCOMING_WEBINARS_ENDPOINT = "webinar/scorllUpcomingWebinar";
export const POST_UPCOMING_WEBINAR_ENDPOINT = "webinar/upcoming";
export const DELETE_UPCOMING_WEBINAR_ENDPOINT = "webinar/upcoming";
export const GET_RECORDED_WEBINARS_ENDPOINT =
  "webinar/scorllPaginationWebinarRecorded";

// My Links - Invite links
export const SAVE_INVITE_ENDPOINT = "my-links/save-invite";

//validate reset pwd link
export const VALIDATE_RESET_LINK="validate-reset-password-link";
// change password from login 
export const CHANGE_PASSWORD_FROM_LOGIN="change-password-from-login"


//refund api for external mail link
export const REFUND_ADD_DETAILS ="refund/add-details";

export const REFUND_FORM_LINK_VALIDATE = "refund/validate-refund-form-link"

// ASCII CODE SEPARATION FOR AVOIDING PARTICULAR KEY FROM KEYBOARD

const ASCII = [];
for (let i = 0; i <= 255; i++) {
  ASCII.push(String.fromCharCode(i));
}
// SPECIAL_CHARACTERS
export const SPECIAL_CHARACTERS = [];
for (let i = 0; i <= 47; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}
for (let i = 58; i <= 64; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}

for (let i = 91; i <= 96; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}
for (let i = 123; i <= 255; i++) {
  SPECIAL_CHARACTERS.push(ASCII[i]);
}

SPECIAL_CHARACTERS.push(["Alt", "®", "©", "™"]);

// NUMBERS
export const NUMBERS = [];
for (let i = 48; i <= 57; i++) {
  NUMBERS.push(ASCII[i]);
}

// SMALL_ALPHABETS
export const SMALL_ALPHABETS = [];
for (let i = 97; i <= 122; i++) {
  SMALL_ALPHABETS.push(ASCII[i]);
}

// CAPITAL_ALPHABETS
export const CAPITAL_ALPHABETS = [];
for (let i = 65; i <= 90; i++) {
  CAPITAL_ALPHABETS.push(ASCII[i]);
}

export const NUMPAD_KEYS = [];

export const NAVIGATION_KEYS = [
  "Backspace",
  "Delete",
  "Tab",
  "Escape",
  "Enter",
  "Home",
  "End",
  "ArrowLeft",
  "ArrowRight",
  "Clear",
  "Copy",
  "Paste"
];

export const FUNCTION_KEYS = [];

export const SPL_CHAR = [' ','.','_','-'];

export const SPECIAL_SYMBOLS = [
  '!', '@', '#', '$', '%', '&', '(', ')', '+', ',', ':', ';', '=',  '?', '[',
  ']', '^', '`', '{', '|', '}', '~']
