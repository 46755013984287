import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  userInfo: any; // = new BehaviorSubject<any>(null);
  profilePic!: string; // = new BehaviorSubject<any>(null);
  profilePicForHeader = new BehaviorSubject<any>(null);
  profileData = new BehaviorSubject<any>(null);
  countriesData: Array<any>; // = new BehaviorSubject<any>(null);
  storageKey = ' ';
  founderDetails = new Map<string, string>();

  constructor(private _encDecrService: EncrDecrService) {}

  getUserInfo() {
    return this.userInfo;
  }

  setUserInfo(data: any) {
    this.userInfo = data;
  }

  getProfilePic() {
    return this.profilePic;
  }

  getProfilePicForHeader() {
    return this.profilePicForHeader.asObservable();
  }

  setProfilePic(data: string) {
    this.profilePicForHeader.next(data);
    this.profilePic = data;
  }

  // environment.storageKey;
  getLoggedInUser(key: string, json?: boolean) {
    if (json) {
      const user =
        key === 'user'
          ? sessionStorage.getItem(this.storageKey + '_' + key)
          : this.founderDetails.get(this.storageKey + '_' + key);
      return user == undefined ? '' : JSON.parse(user);
    } else {
      const user =
        key === 'user'
          ? sessionStorage.getItem(this.storageKey + '_' + key)
          : this.founderDetails.get(this.storageKey + '_' + key);
      return user;
    }
  }

  setLoggedInUser(key: string, data: any, json: boolean) {
    if (json) {
      key === 'user'
        ? sessionStorage.setItem(
            this.storageKey + '_' + key,
            JSON.stringify(data)
          )
        : this.founderDetails.set(
            this.storageKey + '_' + key,
            JSON.stringify(data)
          );
    } else {
      key === 'user'
        ? sessionStorage.setItem(this.storageKey + '_' + key, data)
        : this.founderDetails.set(this.storageKey + '_' + key, data);
    }
  }

  setOstaffLoggedInUser(key: any, data: any, json: boolean) {
    if (json) {
      localStorage.setItem('sessionUser' + '_' + key, JSON.stringify(data));
    } else {
      localStorage.setItem('sessionUser' + '_' + key, data);
    }
  }

  getOstaffLoggedInUser(key: any, json?: boolean) {
    if (localStorage.getItem('sessionUser' + '_' + key) != undefined) {
      if (json) {
        return JSON.parse(localStorage.getItem('sessionUser' + '_' + key));
      } else {
        localStorage.getItem('sessionUser' + '_' + key);
      }
    } else {
      return false;
    }
  }

  getUserData(key: any) {
    const ostaffUserData = this.getOstaffLoggedInUser('user', true);
    if (ostaffUserData) {
      return ostaffUserData[key];
    }
    return null;
  }

  removeUser(key: string) {
    localStorage.removeItem(this.storageKey + '_' + key);
    sessionStorage.removeItem(this.storageKey + '_' + key);
    this.founderDetails.delete(this.storageKey + '_' + key);
    sessionStorage.removeItem("campaignId");
  }

  isLoggedIn() {
    return this.getLoggedInUser('user', true) ? true : false;
  }

  getProfileData() {
    return this.profileData.asObservable();
  }

  setProfileData(data: any) {
    this.profileData.next(data);
  }

  setCountriesData(data: Array<any>) {
    this.countriesData = data;
  }

  getCountriesData() {
    return this.countriesData;
  }

  getFounderDetails() {
    const userDetails = this.founderDetails.get(
      this.storageKey + '_' + 'userDetails'
    );
    return userDetails
      ? JSON.parse(
          this.founderDetails.get(this.storageKey + '_' + 'userDetails')
        )
      : '';
  }

  getFounderName() {
    return (
      this.getFounderDetails()['userFName']+
      ' ' +
      this.getFounderDetails()['userLName']
    );
  }

  getNdaStatus() {
    return this._encDecrService
      .decrypt(this.getFounderDetails()['ndaStatus'])
      .toLowerCase();
  }

  isLeader() {
    return (
      this._encDecrService.decrypt(this.getFounderDetails()['isLeader']) == 'true' || this._encDecrService.decrypt(this.getFounderDetails()['isLeader']) == '1'
    );
  }

  getFounderUserId() {
    return +this._encDecrService.decrypt(this.getFounderDetails()['userId']);
  }

  getFounderEmail() {
    return this._encDecrService.decrypt(this.getFounderDetails()['userEmail']);
  }

  getFounderPhone() {
    return this._encDecrService.decrypt(
      this.getFounderDetails()['userPhoneNo']
    );
  }

  getFounderPaymentStatus() {
    return this._encDecrService.decrypt(
      this.getFounderDetails()['paymentStatus']
    );
  }

  getFounderOstaffStatus() {
    return (
        this.getFounderDetails()['ostaffUserRegStatus']
    );
  }

  getEncryptValueOfZero() {
    return this._encDecrService.encrypt('0');
  }
}
