import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
  name: "serverFileExists"
})
export class ServerFileExistsPipe implements PipeTransform {
  backendUrl = environment.apiUrl;

  transform(value: any, type: string): any {
    let url = this.backendUrl + "file/downloadFile/" + value;

    if(type === "profilePic" && value == "0"){
      return "assets/user/images_v1/profile_pics/defaultImg.png";   
    }
      return url;
  }
}