import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class OMailService {
  omailBaseApiUrl = environment.omailApiUrl;
  webBaseUrl = environment.omailUiUrl;
  httpClientBackend: HttpClient;
  encryptInfo:any;
  constructor(private _http: HttpClient, private httpBackend: HttpBackend) {
    this.httpClientBackend = new HttpClient(this.httpBackend);
  }

  checkUser(userName) {
    return this.httpClientBackend.get(
      `${this.omailBaseApiUrl}checkUserName/${userName}`
    );
  }
  checkOESAndOmailEmail(ofounderId:any) {
    // console.log(ofounderId)
    const encryptedPayload = this.encrypt({ofounderId:ofounderId});
    return this.httpClientBackend.post(`${environment.OESOmailApi}validateOFounderRegistration`,encryptedPayload);
  }
  createOmailUser(formData) {
    return this.httpClientBackend.post(
      `${this.omailBaseApiUrl}createOfounderUsers`,
      formData
    );
  }
  getUsersuggestion(userName) {
    return this.httpClientBackend.get(
      `${this.omailBaseApiUrl}getSuggetionNames/${userName}`
    );
  }

  handShakeWithOmail(oMailUserId: string) {
    return this.httpClientBackend.post(`${this.omailBaseApiUrl}handShakeOpt`, {
      userName: oMailUserId,
    });
  }

  addOmailInGoFounder(emailId) {
    return this._http.post(
      `${environment.apiUrl}saveOmailEmailId?omailEmailId=${emailId}`,
      {}
    );
  }
  addAgreements(payload: any) {
    return this._http.post(`${environment.apiUrl}docs/save-prod-doc`, payload);
  }
  getAgreements(payload: any) {
    return this._http.post(`${environment.apiUrl}docs/check-docs`, payload);
  }
  getNewToken() {
    return this._http.get(`${environment.apiUrl}refreshToken`);
  }

  updateOmailIdInOBS(payload:any):Observable<any>{
    return this.httpClientBackend.post(`${environment.obsWebApiUrl}o-founders/update-omailId`, payload);
  }

  encrypt(data: any) {
    const accessKey =  ')Npassive@2021';
    if(data != undefined){
    this.encryptInfo = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      accessKey
    ).toString();
    
    const deData = CryptoJS.AES.decrypt(this.encryptInfo, accessKey);
    //  console.log(JSON.parse(deData.toString(CryptoJS.enc.Utf8)))
    const decryptedInfo = JSON.parse(deData.toString(CryptoJS.enc.Utf8));
    // console.log(decryptedInfo)
    return this.encryptInfo;
    }
  }
}
