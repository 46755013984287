import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { LegalDisclaimerComponent } from 'src/app/components/legal-disclaimer/legal-disclaimer.component';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LegalDisclaimerResolver implements Resolve<boolean> {
  constructor(
    private modalService: NgbModal,
    private _ls: LocalStorageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (!this._ls.getLoggedInUser('isRedirectedToPaymentGateway', true)) {
      this.modalService.open(LegalDisclaimerComponent, {
        size: 'xl',
        backdrop: 'static',
        windowClass:'legal-disclaimer'
      });
    }
    return of(true);
  }
}
