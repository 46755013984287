<div class="model-body">
  <img
    src="../../../../assets/images/payment/payment-failed.png"
    alt="payment"
  />
  <h5>Payment Failed</h5>
  <p>
    Your attempt to make the payment is unsuccessful. Please do not worry as we
    will get back to you within the shortest possible time to help book your
    Founders position.
  </p>

  <p>
    For further queries, e-mail us at:
    <a target="_blank" rel="noopener">support@ofounders.net</a>
  </p>
  <button class="custom-btn-fill" (click)="close()">Try Again</button>
</div>
