import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "totalCount"
})
export class TotalCountPipe implements PipeTransform {
  transform(values: number[]): number {
    return values.reduce((a, b) => a + b, 0);
  }
}
