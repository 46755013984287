export const environment = {
  production: true,
  applicationUrl: 'https://ofounders.net/',
  google_maps: {
    GOOGLE_MAPS_API_KEY: 'AIzaSyBSg1b4glqZ20dEo98-9yZlDF4kQPoFw7s',
  },
  apiUrl: 'https://api.ofounders.net/',
  trimUrl: 'https://o-trim.co/',
  oleadOrgApiEndPoint: 'https://odeskadminapi.ofounders.net/api/',
  supportApiEndPoint: 'https://ocrmapi.ofounders.net/api/',
  cloudFrontUrl: 'https://d1ek26l1xq8v6r.cloudfront.net/',
  cloudFrontUrlForRecordedWebinars: 'https://d1bsww03q215pw.cloudfront.net/',
  myLinksS3URL:
    'https://d1ek26l1xq8v6r.cloudfront.net/O_FOUNDER_ORGANIC/MY_LINKS/assests/',
  onPassiveBaseUrl:
    'https://d1ek26l1xq8v6r.cloudfront.net/O_FOUNDER_ORGANIC/MY_LINKS/assests/ONPASSIVE_LINK/',
  coinPaymentUrl: 'https://www.coinpayments.net/',
  oTrackerIpUrl: 'https://otrackerapi.ofounders.net/ipTracker/api/country/ByIP',
  OFounderOTrackerIpUrl: 'https://otrackerapi.ofounders.net/ipTracker/api/',
  omailApiUrl: 'https://omailapi.ofounders.net/',
  OESOmailApi: 'https://omailapi.onpassive.com/',
  omailUiUrl: 'https://omail.ofounders.net',
  omailDomainExtension: '@omail.ai',
  ochatApiUrl: 'https://ochat.ofounders.net/',
  otrackerDashboard: 'https://otracker.ofounders.net/',
  ostaffUiUrl: 'https://ostaffadmin.onpassive.com/',
  OstaffApiUrl: 'https://ostaffapi.onpassive.com/',
  accessKey: 'sTtHMA7pNBD7xSAv',
  OBS_AccessKey: 'QmzJhIUAgTzb9qBv',
  stripePaymentKey: 'pk_live_yLhJIyEgDC8KFXaL5lZtZ2oj',
  paymentGateway: 'easeBuzz', // razorPay, payuMoney, mcPayment, easeBuzz
  timeZonesForSpecificPaymentGateway: ['Asia/Kolkata1'],
  shouldCaptchaPresent: false,
  shouldOtpPresent: true,
  postLengthInOnet: 5000,
  onetDefaultCountry: 238, // For United States - 238, All - 251
  froalaKey: 'mPD4tD2G2F1H1H1C3B1lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2E1C5F1G2F1H4C10==',
  isCkEditor: false,
  upcomingWebinarDefaultImage:
  'https://d1ek26l1xq8v6r.cloudfront.net/O_FOUNDER_ORGANIC/UPCOMING-WEBINAR/FILES/upcoming-webinar-default-1.svg',
  onetworkPostIcon:'https://d1ek26l1xq8v6r.cloudfront.net/O_FOUNDER_ORGANIC/O-NET/images/Earth_GLobe_o-net.gif',
  obsWebUrl: 'https://oes.onpassive.com/',
  //obsWebUrl: 'https://www.onpassive.com/oes/',
  obsWebApiUrl: 'https://oes-api.onpassive.com/',
};
