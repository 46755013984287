import { Directive, HostListener } from '@angular/core';
import {
  SMALL_ALPHABETS,
  CAPITAL_ALPHABETS,
  NAVIGATION_KEYS,
  SPL_CHAR,
  NUMBERS,
  SPECIAL_SYMBOLS,
} from 'src/app/util/constants';
@Directive({
  selector: '[appAlphabetOnly]',
})
export class AlphabetOnlyDirective {
  private key;
  @HostListener('keypress', ['$event']) onKeypress(event: KeyboardEvent) {
    this.key = event.key;
    if (
      [
        ...SMALL_ALPHABETS,
        ...CAPITAL_ALPHABETS,
        ...NAVIGATION_KEYS,
        ...SPL_CHAR,
        ...NUMBERS,
        ...SPECIAL_SYMBOLS,
      ].indexOf(this.key) === -1
    ) {
      event.preventDefault();
    }
  }
}
