import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateComparison"
})
export class DateComparisonPipe implements PipeTransform {
  cutOffDate = new Date(2021, 0, 1); // Jan 01, 2021
  transform(elementDate: string, ...args: unknown[]): unknown {
    return this.cutOffDate > new Date(elementDate);
  }
}
