import { Pipe, PipeTransform } from "@angular/core";

/* Pipe to trim given URL/text if length is above certain length
 * and replace it with ...
 */
@Pipe({
  name: "truncate"
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, showExtention?: boolean) {
    if (showExtention) {
      const fileType = value.split(".")[value.split(".").length - 1];
      return value.length < limit
        ? value
        : value.slice(0, limit) + "." + fileType;
    }
    return value.length < limit ? value : value.slice(0, limit) + "...";
  }
}
