import { Directive, HostListener } from "@angular/core";
@Directive({
  selector: "[emailOnly]"
})
export class EmailDirective {
  private key;
  private navigationKeys = [
    "!",
    '"',
    "#",
    "%",
    "&",
    "(",
    ")",
    "*",
    "+",
    ",",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "[",
    "]",
    "^",
    "`",
    "{",
    "|",
    "}",
    "~",
    " ",
    "\\"
  ];
  @HostListener("keydown", ["$event"]) onKeydown(event: KeyboardEvent) {
    this.key = event.key;
    if (this.navigationKeys.indexOf(this.key) !== -1) {
      event.preventDefault();
    }
  }
}
