import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncrDecrService {
  hashmapky!: CryptoJS.lib.WordArray;
  mapk = environment.accessKey;
  constructor() {
    this.hashmapky = CryptoJS.enc.Utf8.parse(this.mapk);
  }

  //The set method is use for encrypt the value.
  encrypt(value: string): string {
    const encryptedValue = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      this.hashmapky,
      {
        keySize: 128 / 8,
        iv: this.hashmapky,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();

    // const re = /\//gi;
    // const re1 = /\+/gi;
    // const newstr = encryptedValue.replace(re, '_');
    // const newstr1 = newstr.replace(re1, '-');
    // console.log(String(this.replaceSpecialCharactersForEnc(encryptedValue)))
    return this.replaceSpecialCharactersForEnc(encryptedValue);
  }

  //The get method is use for decrypt the value.
  decrypt(encryptedText: string): string {
    if (!encryptedText) {
      return '';
    }
    const value = this.replaceSpecialCharacters(encryptedText);
    const decrypted = CryptoJS.AES.decrypt(value, this.hashmapky, {
      keySize: 128 / 8,
      iv: this.hashmapky,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  replaceSpecialCharacters(strToDecrypt: string) {
    return strToDecrypt
      .replace(/%2B/gi, "+")
      .replace(/%2D/gi, '-')
      .replace(/%2A/gi, '*')
      .replace(/%2C/gi, ',')
      .replace(/%2E/gi, '.')
      .replace(/%2F/gi, '/')
      .replace(/%20/gi, '+')
      .replace(/%3D/gi, '=');
  }
  replaceSpecialCharactersForEnc(strToDecrypt: string) {
    return strToDecrypt
      .replace(/\+/g, "%2B")
      .replace(/\-/g, "%2D")
      .replace(/\*/g, "%2A")
      .replace(/\,/g, "%2C")
      .replace(/\./g, "%2E")
      .replace(/\//g, "%2F")
      .replace(/\=/g, "%3D")      
      .replace(/\'+'/g, "%20");
  }
}
