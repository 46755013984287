import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { CustomValidator } from '../validators/custom.validator';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  editUpcomingwebinarData:any;
  httpClientBackend: HttpClient;
  pageType!: string;
  fileTypes = ['.jpg', '.png'];
  allowedFileTypesInLeadershipCouncil = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/jpg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'video/mp4',
    'image/svg+xml',
    'application/pdf',
    // 'text/plain',
    // "application/vnd.ms-excel"
  ];
  createPostTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'video/mp4',
    'image/gif',
    'image/bmp',
  ];

  constructor(
    private httpBackend: HttpBackend,
    private modalService: NgbModal
  ) {
    this.httpClientBackend = new HttpClient(this.httpBackend);
  }

  showSuccessMessage(message: string, helpText?: string) {
    return Swal.fire({
      title: message,
      icon: 'success',
      html: helpText,
      confirmButtonColor: '#37AC00',
      confirmButtonText: 'OK',
      heightAuto: false,
      // iconHtml: '<img src="/assets/images/icons/success-toaster.svg">'
    });
  }

  showWarningMessage(message: string, helpText?: string) {
    return Swal.fire({
      title: message,
      html: helpText,
      icon: 'warning',
      heightAuto: false,
      confirmButtonColor: '#FE7A00',
      confirmButtonText: 'OK',
      iconHtml: '<img src="/assets/images/icons/alert-toaster.svg">',
    });
  }

  showErrorMessage(message: string, helpText?: string) {
    return Swal.fire({
      title: message,
      icon: 'error',
      html: helpText,
      confirmButtonColor: '#E84C3D',
      confirmButtonText: 'OK',
      heightAuto: false,

      iconHtml: '<img src="/assets/images/icons/error-toaster.svg">',
    });
  }

  showDeleteMessage() {
    return Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FE7A00',
      heightAuto: false,
      cancelButtonColor: '#FFAC30',
      confirmButtonText: 'Yes, delete it!',
      iconHtml: '<img src="/assets/images/icons/alert-toaster.svg">',
    });
  }

  trimCkEditorContent(content: string) {
    return content.replace(
      /^((\n)+|(<p>(&[^&]*;(\s*))+<\/p>))+|(\n|(<p>(&[^&]*;(\s*))+<\/p>))+$/g,
      ''
    ); // Starting/ending either with space or new line
  }

  isEditorContainsNoData(content: string) {
    const editorData = this.trimCkEditorContent(content);
    return (
      editorData === '' ||
      editorData === '<p></p>' ||
      editorData === '<p></p>\n'
    );
  }

  // utility to get pagination data
  getPaginationData(
    event: any,
    params: {
      pageIndex: number;
      pageSize: number;
      sort: string;
      searchValue: string;
      start: number;
      limit: number;
      notificationCount: number;
    }
  ) {
    if (event.pageIndex === 0 || params.limit !== event.pageSize) {
      params.start = 0;
      params.pageIndex = 0;
    } else {
      params.pageIndex = event.pageIndex;
      params.start = event.pageIndex * event.pageSize;
    }

    params.limit = params.pageSize = event.pageSize || params.pageSize;
  }

  fetchResult(method: string, endPoint: string, data?: any, options?: any) {
    const body = data || '';
    const reqOptions = options || '';

    switch (method) {
      case 'POST':
        return this.httpClientBackend.post(endPoint, body, reqOptions);
      case 'PUT':
        return this.httpClientBackend.put(endPoint, body, reqOptions);
      case 'DELETE':
        return this.httpClientBackend.delete(endPoint, body);
      default:
        return this.httpClientBackend.get(endPoint, body);
    }
  }

  getUserDetaisForMyLinksSection(aRoute: ActivatedRoute) {
    let userDetails: any;
    let userName, country, city, referralId: string;
    let countriesList;
    aRoute.queryParamMap.subscribe((params) => {
      referralId = params.get(params.keys[0]);
    });

    aRoute.data.subscribe((data) => {
      if (data) {
        if (data.sponsorData.user) {
          userDetails = data.sponsorData.user;
          userName = userDetails.firstName + ' ' + userDetails.lastName;
        }

        if (data.locationData) {
          country = data.locationData.country;
          city = data.locationData.city;
        }

        if (data.countriesData) {
          countriesList = data.countriesData;
        }
      }
    });

    return [userDetails, userName, country, city, countriesList, referralId];
  }

  openModal(content: any) {
    const script = document.createElement('script');
    script.onload = function () {
      //do stuff when the this.script is loaded
    };
    script.src = 'https://unpkg.com/video.js/dist/video.min.js';
    document.head.appendChild(script); //or something of the likes

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          console.info(`Closed with: ${result}`);
        },
        (reason) => {
          console.info(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getRegisterForm() {
    return new FormGroup({
      firstname: new FormControl(
        '',
        Validators.compose([
          Validators.required,

          Validators.maxLength(50),
          CustomValidator.cannotContainOnlyNumbers,
          CustomValidator.cannotContainOnlySpecialCharacters,
          CustomValidator.cannotContainConsecutiveSpaces,
          CustomValidator.cannotContainConsecutiveDots,
          CustomValidator.cannotContainConsecutiveHyphens,
          CustomValidator.cannotContainConsecutiveUnderScores,
        ])
      ),
      lastname: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          CustomValidator.cannotContainOnlyNumbers,
          CustomValidator.cannotContainOnlySpecialCharacters,
          CustomValidator.cannotContainConsecutiveSpaces,
          CustomValidator.cannotContainConsecutiveDots,
          CustomValidator.cannotContainConsecutiveHyphens,
          CustomValidator.cannotContainConsecutiveUnderScores,
        ])
      ),
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.maxLength(50),
          Validators.pattern('[a-z0-9A-Z._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      ),
      password: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[/!@#$%?\\^&*()-+=]).{8,}'
          ),
          CustomValidator.canContainSomeSpeciacharacters,
        ])
      ),
      phone: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(20),
          Validators.pattern('^[0-9]+$'),
          // CustomValidator.cannotContainDash
        ])
      ),
      country_id: new FormControl('', [Validators.required]),
      // enteredCaptcha: new FormControl("", [Validators.required])
    });
  }

  allowOnlyNumbers(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode >= 48 && charCode <= 57) || charCode == 43) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  extractFileName(fileWithPath: string): string {
    if (!fileWithPath) return '';
    return fileWithPath.split('/')[fileWithPath.split('/').length - 1];
  }
  validateUploadedFile(
    file: File,
    fileFormats: string[],
    maxSize = 2000,
    skipSizeValidation?: boolean
  ): string | null {
    if (file) {
      if (fileFormats.indexOf(file.type) > -1) {
        if (!skipSizeValidation && file.size / 1024 > maxSize) {
          this.showWarningMessage(
            `Uploaded file exceeds maximum size of ${maxSize / 1000}MB`
          );
          return null;
        }
        return file.type;
      } else {
        const allowedFileTypes = fileFormats.map((type) => {
          const fileType = type.split('/')[1].toUpperCase();
          switch (fileType) {
            case 'text/plain':
              return 'TXT';
            case 'image/svg+xml':
              return 'SVG';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
              return 'DOCX';
            default:
              return fileType;
          }
        });
        Swal.fire(
          'Please upload valid file format',
          `Only ${allowedFileTypes} file types are allowed`,
          'warning'
        );
        return null;
      }
    }
    return null;
  }
}
