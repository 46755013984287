import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'asUrl',
})
export class BackgroundUrlPipe implements PipeTransform {
  cloudFrontUrl = environment.cloudFrontUrl;
  cloudFrontUrlForRecordedWebinars =
    environment.cloudFrontUrlForRecordedWebinars;

  transform(value: string, isRecrodedWebinar = false): string {
    return `url(${
      isRecrodedWebinar
        ? this.cloudFrontUrlForRecordedWebinars
        : this.cloudFrontUrl
    }${value})`;
  }
}
