import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { InvoiceLinkComponent } from './components/invoice-link/invoice-link.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginGuard } from './shared/guards/login.guard';
import { PaymentGuard } from './shared/guards/payment.guard';
import { LegalDisclaimerResolver } from './shared/resolvers/legal-disclaimer.resolver';
import { PaymentResolver } from './shared/resolvers/payment.resolver';

const routes: Routes = [
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '404', component: PageNotFoundComponent },

  {
    path: 'auth',
    // resolve: { disclaimer: LegalDisclaimerResolver },
    loadChildren: () =>
      import('./modules/auth-pages/auth-pages.module').then(
        (m) => m.AuthPagesModule
      ),
  },

  {
    path: 'registration',
    // resolve: { disclaimer: LegalDisclaimerResolver },
    // loadChildren: () =>
    //   import('./modules/auth-pages/registration/registration.component').then(
    //     (m) => m.RegistrationModule
    //   ),
    loadChildren: () =>
      import(
        './modules/auth-pages/registration-stop/registration-stop.component'
      ).then((m) => m.RegistrationStopModule),
  },

  { path: 'verifyEmailId', component: VerifyEmailComponent },
  {
    path: 'pdfInvoice',
    component: InvoiceLinkComponent,
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
  },

  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
    canActivate: [LoginGuard, PaymentGuard], //McpGuard
    resolve: {
      disclaimer: LegalDisclaimerResolver,
      paymentData: PaymentResolver,

      // profileData: ProfileResolver, timeZoneData: TimeZoneResolver
    },
  },

  {
    path: 'founderinvitation',
    loadChildren: () =>
      import('./link_pages/invitationlink/invitationlink.module').then(
        (m) => m.InvitationlinkModule
      ),
  },
  {
    path: 'my_presentation',
    loadChildren: () =>
      import('./link_pages/presentation/presentation.module').then(
        (m) => m.presentationModule
      ),
  },
  {
    path: 'foundergreeting',
    loadChildren: () =>
      import('./link_pages/greetings/greetings.module').then(
        (m) => m.GreetingsModule
      ),
  },
  {
    path: 'founderinvite',
    loadChildren: () =>
      import('./link_pages/invite/invite.module').then((m) => m.InviteModule),
  },
  {
    path: 'onpassivelinks',
    loadChildren: () =>
      import('./link_pages/onpassivelink/onpassivelink.module').then(
        (m) => m.OnpassivelinkModule
      ),
  },
  {
    path: 'founderwebinar',
    loadChildren: () =>
      import('./link_pages/webinar/webinar.module').then(
        (m) => m.WebinarModule
      ),
  },
  // { path: ':unknown', redirectTo: '' },
  {
    path: '',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./modules/main-layout/main-layout.module').then(
        (m) => m.MainLayoutModule
      ),
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
