import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { WebserviceService } from '../shared/services/webservice.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EncrDecrService } from '../shared/services/encr-decr.service';
import { retry } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private _ls: LocalStorageService,
    private _service: WebserviceService,
    private deviceService: DeviceDetectorService,
    private encrDecrService: EncrDecrService,

  ) { }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const currentUser = this._ls.getLoggedInUser('user', true);
    if (
      currentUser != null ||
      req.url.indexOf(environment.coinPaymentUrl) === -1
    ) {
      const headers = {};
      const deviceInfo = this.deviceService.getDeviceInfo();
      // console.log(deviceInfo)
      headers['browserName'] = `${deviceInfo.browser}`;
      headers['browserVersion'] = `${deviceInfo.browser_version}`;
      headers['deviceType'] = `${deviceInfo.deviceType}`;
      headers['osName'] = `${deviceInfo.os}`;
      headers['osVersion'] = `${deviceInfo.os_version}`;
      if (sessionStorage.getItem(' _CB') == null) {
        sessionStorage.setItem(' _CB', JSON.stringify(headers));
      }
      // const userDetails = this._ls.getLoggedInUser('userDetails', true);
      // const paymentStatus = this.encrDecrService.decrypt(userDetails['paymentStatus']);
      const paymentStatus = this.encrDecrService.decrypt(sessionStorage.getItem(' _uxid'));
      if (currentUser && paymentStatus == 'confirmed' || currentUser && paymentStatus == 'refund') {
        headers['authorization'] = 'Bearer ' + currentUser;
      }
      req = req.clone({
        setHeaders: headers,
      });
    }
    return next.handle(req).pipe(

      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: event.body });
        }
        return event;
      }),
      retry(2),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.error('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.error('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        switch (error.status) {
          case 400:
            Swal.fire('Error!', error?.error?.exMsg, 'error');
            break;
          case 401:
            Swal.fire('Please login to continue', '', 'error');
            break;
          case 404:
            Swal.fire('Error!', `${error?.error?.details[0]}`, 'error');
            break;
          case 403:
            console.error('Getting 403 error while accessing ' + req.url);
            Swal.fire('Session expired', 'Please login to continue', 'warning');
            this._service.closeAllPopups();
            this._service.logout();
            break;
          case 415:
            Swal.fire('File type is not supported', '', 'error');
            break;
          case 417:
            Swal.fire(error?.error?.message, '', 'warning');
        }
        console.error(errorMsg);
        return throwError(error);
      })
    );
  }
}
